import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import apiService from "../services/apiService";
import { Checkmark } from "react-checkmark";
import baseUrl from "../baseUrl";
import { Button } from "@mui/material";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { setUserPlan } from "features/userSlice";

const PaymentSuccess = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await apiService.post(
          `${baseUrl}/payment/verify-token`,
          { token }
        );
        if (response.success) {
          const plan = _.get(response, "data.plan", "free");
          dispatch(setUserPlan(plan));
          setIsVerified(true);
          window.gtag("event", "conversion", {
            send_to: "AW-11482717818/4W-rCOT6zOMZEPq8seMq",
            value: response.price || 100,
            currency: "INR",
          });
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Token verification failed", error);
        navigate("/");
      }
    };

    verifyToken();
  }, [token, navigate]);

  if (!isVerified) {
    return (
      <div className=" text-xxl text-white flex justify-center items-center h-[89vh]">
        Verifying...
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center text-white flex-col h-[89vh] gap-4 ">
      <Checkmark size="xLarge" />
      <h1>Thank you for your purchase!</h1>
      <p>Your transaction was successful.</p>
      <Button onClick={() => navigate("/")}>Go on Homepage</Button>
    </div>
  );
};

export default PaymentSuccess;
