import React from "react";

function Input({ label, id, ...inputProps }) {
  return (
    <div style={{ width: "100%" }}>
      <label className="block mb-2 text-gray-300 font-medium" htmlFor={id}>
        {label}
      </label>
      <div>
        <input
          id={id}
          className="w-full resize-none bg-gray-700 text-white border border-gray-500 p-4 leading-tight focus:outline-none focus:bg-gray-600 focus:border-indigo-500"
          {...inputProps}
        />
      </div>
    </div>
  );
}

export default Input;
