/**
 * Generalized function to update specific fields in an infinite query cache
 * @param {QueryClient} queryClient - Instance of the QueryClient.
 * @param {Array} queryKey - The query key used for the infinite query.
 * @param {string|number} itemId - The unique identifier of the item to update.
 * @param {Object} updates - An object containing the fields to update and their new values.
 */
export const updateItemInInfiniteQuery = (
  queryClient,
  queryKey,
  itemId,
  updates
) => {
  queryClient.setQueryData(queryKey, (oldData) => {
    if (!oldData) return oldData;
    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        posts: page.posts.map((item) =>
          item._id === itemId ? { ...item, ...updates } : item
        ),
      })),
    };
  });
};
