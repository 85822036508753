import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import Input from "../../common/Input";
import { css } from "@emotion/react";
import { DotLoader } from "react-spinners";
import { Dropdown } from "UIComponents";
import { GENDER } from "./constants";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const RegisterComponent = ({
  credentials,
  handleRegisterSubmit,
  handleChange,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown((passwordShown) => !passwordShown);
  };

  const handleRegisterClick = async () => {
    try {
      setLoading(true);
      await handleRegisterSubmit();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-1 md:gap-2 w-[90%] md:w-4/5">
      <Dropdown
        value={credentials.gender}
        onChange={handleChange}
        options={GENDER}
        name="gender"
        dropdownClassName="!bg-[#0d1525] rounded-[8px] border-[1px] border-[#8899a8] py-[12px] md:py-4  md:px-[10px]"
      />
      <Input
        name="username"
        type="text"
        placeholder="Enter Username"
        onChange={handleChange}
        value={credentials.username}
        required
        className="inputBar1"
      />
      <div className="floeuy">
        <Input
          name="password"
          type={passwordShown ? "text" : "password"}
          placeholder="Enter Password"
          value={credentials.password}
          onChange={handleChange}
          required
          className="inputBar1"
        />
        <div onClick={togglePasswordVisibility}>
          {passwordShown ? (
            <EyeOffIcon className="eyeIcpn" />
          ) : (
            <EyeIcon className="eyeIcpn" />
          )}
        </div>
      </div>
      <div className="floeuy">
        <Input
          name="confirmPassword"
          type={passwordShown ? "text" : "password"}
          placeholder="Confirm Password"
          onChange={handleChange}
          value={credentials.confirmPassword}
          required
          className="inputBar1"
        />
        <div onClick={togglePasswordVisibility}>
          {passwordShown ? (
            <EyeOffIcon className="eyeIcpn" />
          ) : (
            <EyeIcon className="eyeIcpn" />
          )}
        </div>
      </div>
      <div className="w-full flex justify-center items-center mt-2">
        <button
          onClick={handleRegisterClick}
          className="w-full rounded-xl md:rounded-md bg-[#4BC25E] p-3 text-[#1B2333] flex justify-center"
        >
          {loading ? (
            <DotLoader
              color={"#fff"}
              loading={loading}
              css={override}
              size={20}
            />
          ) : (
            <span className="font-semibold text-sm md:text-base">Register</span>
          )}
        </button>
      </div>
    </div>
  );
};
