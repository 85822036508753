import { createSlice } from "@reduxjs/toolkit";

const voiceCloneSlice = createSlice({
  name: "voiceClone",
  initialState: {
    voiceId: null,
  },
  reducers: {
    setVoiceId: (state, action) => {
      state.voiceId = action.payload;
    },
    clearVoiceId: (state) => {
      state.voiceId = null;
    },
  },
});

export const { setVoiceId, clearVoiceId } = voiceCloneSlice.actions;

export default voiceCloneSlice.reducer;
