import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setIpAddress,
  setUserName,
  setUserId,
  setAuth,
  setUserEmail,
  setUserPlan,
} from "../features/userSlice";
import { getCookie, setCookie } from "../utils/methods";
import baseUrl from "../baseUrl";

const useGuestAuth = () => {
  const dispatch = useDispatch();

  const { userName, displayRegister, ipAddress } = useSelector(
    (state) => state.user
  );
  const token = getCookie("token");
  const fetchIpAddress = async (signal) => {
    console.log("calling");
    try {
      const ipv6Response = await fetch("https://api6.ipify.org?format=json", {
        signal,
      });
      if (ipv6Response.ok) return (await ipv6Response.json()).ip;
    } catch (e) {
      console.log("Failed to fetch IPv6 address, trying IPv4", e);
    }

    try {
      const ipv4Response = await fetch("https://api.ipify.org?format=json", {
        signal,
      });
      if (ipv4Response.ok) return (await ipv4Response.json()).ip;
    } catch (e) {
      console.log("Failed to fetch IPv4 address", e);
    }
  };

  const fetchAuth = useCallback(
    async (signal) => {
      try {
        const ip_address = ipAddress || (await fetchIpAddress(signal));
        console.log("ipAddress: ", ip_address);
        if (!ipAddress) dispatch(setIpAddress(ip_address));

        const guestResponse = await fetch(`${baseUrl}/auth/guestAuth`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ip_address }),
          signal,
        });

        const { username, token, userId, error, email, plan } =
          await guestResponse.json();

        if (error) {
          console.error("Guest login error:", error);
          return;
        }

        dispatch(setUserName(username));
        dispatch(setUserId(userId));
        dispatch(setAuth(true));
        dispatch(setUserEmail(email));
        dispatch(setUserPlan(plan));
        setCookie("token", token, 7);
      } catch (error) {
        console.error("Fetch error during guest authentication:", error);
      }
    },
    [dispatch, ipAddress, baseUrl]
  );

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const shouldFetchAuth =
      !userName || (userName && userName.startsWith("guest_") && !token);

    if (!displayRegister && shouldFetchAuth) {
      fetchAuth(signal);
    } else if (!displayRegister && !shouldFetchAuth) {
      dispatch(setAuth(true));
    }

    return () => controller.abort();
  }, [userName, token]);

  return { userName, token };
};

export default useGuestAuth;
