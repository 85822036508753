import React, { useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setDisplayRegister } from "../features/userSlice";
import { getCookie } from "../utils/methods";
import RegisterModal from "../components/RegisterModal/RegisterModal";

const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const token = getCookie("token");
  const { isAuth, userName, credits, displayRegister } = useSelector(
    (state) => state.user
  );

  const shouldDisplayRegister = useMemo(() => {
    return !token;
  }, [token]);

  useEffect(() => {
    if (shouldDisplayRegister) {
      dispatch(setAuth(false));
      dispatch(setDisplayRegister(true));
    } else {
      dispatch(setAuth(true));
      dispatch(setDisplayRegister(false));
    }
  }, [userName, credits, shouldDisplayRegister, dispatch]);

  return !isAuth ? (
    displayRegister ? (
      <RegisterModal />
    ) : (
      <Outlet />
    )
  ) : (
    <Outlet />
  );
};

export default ProtectedRoute;
