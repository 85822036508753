import React, { forwardRef, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import apiService from "../services/apiService";
import baseUrl from "../baseUrl";
import Gravatar from "react-gravatar";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

const NotificationDropdown = forwardRef((props, ref) => {
  const { handleClose } = props;
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleGetNotifications = async () => {
    try {
      setLoading(true);
      const response = await apiService.get(
        `${baseUrl}/social/sendNotification?limit=8`
      );
      if (response) {
        setNotifications(response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetNotifications();
  }, []);

  const formatDate = (createdAt) => {
    const date = new Date(createdAt);

    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dayName = days[date.getDay()];
    const monthName = months[date.getMonth()];

    return `${dayName}, ${monthName}`;
  };

  return (
    <div
      ref={ref}
      className="w-full h-[100dvh] top-0 right-0 md:w-1/5 rounded-md md:h-[60vh] text-[#cdcdcd] z-50 absolute md:right-[10%] md:top-[11%] bg-[#1b2333] shadow-2xl flex flex-col"
      style={{
        zIndex: "9999",
      }}
    >
      <div className="h-[12%] flex items-center justify-between border-b border-gray-600 px-4">
        Notifications
        <IoMdClose
          size="1.3rem"
          cursor="pointer"
          color="#cdcdcd"
          onClick={handleClose}
        />
      </div>
      <div className="overflow-auto h-[76%]">
        {loading ? (
          <p className="text-gray-600  text-center font-bold">Loading</p>
        ) : notifications.length === 0 ? (
          <p className="text-gray-600 text-center  font-bold">
            No notifications found
          </p>
        ) : (
          notifications.map((notification, index) => (
            <div
              key={index}
              className="flex w-full overflow-x-hidden items-center gap-2 p-2 px-4"
            >
              {notification.senderProfile &&
              notification.senderProfile.imageUrl ? (
                <img
                  className="user_frien3"
                  src={notification.senderProfile.imageUrl}
                  alt={`Profile of ${notification.senderProfile.username}`}
                />
              ) : (
                <Gravatar
                  email={
                    notification.senderProfile?.username ||
                    notification.username
                  }
                  size={40}
                  default="identicon"
                  className="rounded-full"
                  hash={CryptoJS.MD5(
                    notification.senderProfile?.username ||
                      notification.username
                  ).toString()}
                />
              )}

              <div className="flex flex-col items-start w-4/5 justify-center">
                <p className="font-normal text-sm w-full overflow-hidden whitespace-nowrap text-ellipsis">
                  {notification.notificationMessage}
                </p>
                <p className="text-xs text-gray-600">
                  {formatDate(notification.createdAt)}
                </p>
              </div>
            </div>
          ))
        )}
      </div>
      {notifications.length !== 0 && (
        <div
          className="h-[12%] w-full flex justify-center items-center border-t border-gray-600 cursor-pointer"
          onClick={() => navigate("/notification")}
        >
          More
        </div>
      )}
    </div>
  );
});

export default NotificationDropdown;
