import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import CustomSlider from "./Slider";

const data = {
  bodyType: [
    { label: "Athletic", selected: false },
    { label: "Slim", selected: false },
    { label: "Average", selected: false },
    { label: "Curvy/Stocky", selected: false },
  ],
  skinTone: {
    options: [
      { value: "#381D12", label: "Deep Brown" },
      { value: "#41261B", label: "Brown" },
      { value: "#4A2F1E", label: "Medium Brown" },
      { value: "#553624", label: "Tan" },
      { value: "#613C29", label: "Light Tan" },
      { value: "#6C412E", label: "Beige" },
      { value: "#774D34", label: "Light Beige" },
      { value: "#81583A", label: "Peach" },
      { value: "#8E603E", label: "Pale" },
      { value: "#9D6941", label: "Pale" },
      { value: "#A77248", label: "Pale" },
      { value: "#BB8255", label: "Pale" },
      { value: "#BB8255", label: "Pale" },
    ],
    selected: "",
  },
  hair: {
    style: [
      { label: "Short", img: "", selected: false },
      { label: "Medium", img: "", selected: false },
      { label: "Long", img: "", selected: false },
      { label: "Updos", img: "", selected: false },
    ],
    texture: [
      { label: "Straight", img: "", selected: false },
      { label: "Wavy", img: "", selected: false },
      { label: "Curly", img: "", selected: false },
      { label: "Coily/Kinky", img: "", selected: false },
    ],
    color: {
      options: [
        { value: "#2D1B1A", label: "Deep Brown" },
        { value: "#4E302B", label: "Dark Brown" },
        { value: "#6F4B43", label: "Medium Brown" },
        { value: "#8E5F52", label: "Light Brown" },
        { value: "#B07A68", label: "Tan" },
        { value: "#C48A78", label: "Light Tan" },
        { value: "#D6A393", label: "Beige" },
        { value: "#E7BBAF", label: "Fair Beige" },
        { value: "#F2D4CA", label: "Pale" },
        { value: "#FBE8DD", label: "Ivory" },
      ],
      selected: "",
    },
  },
  height: {
    value: 2.5,
    unit: "m",
  },
  navigation: {
    next: {
      label: "Next",
      action: "goToNextStep",
    },
  },
};

export const Step3 = () => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div className="flex flex-col justify-start items-start h-full gap-4 px-16 w-full">
      <div className="flex flex-col w-full">
        {/* BODY TYPE */}
        <p className="body-text font-bold text-white text-left">Body Type</p>
        <div className="flex justify-between gap-8 w-full">
          {data.bodyType.map((item, index) => (
            <button
              key={index}
              className={` w-1/5 rounded-lg bg-overlayBlue border border-gray4`}
            >
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  width="100%"
                  height={150}
                  className="rounded-lg"
                />
              ) : (
                <img src="h-20 w-full rounded-md " />
              )}
              <p className="py-2 px-4 text-white ">{item.label}</p>
            </button>
          ))}
        </div>
      </div>

      {/* SKIN TONE */}
      <div className="flex flex-col w-full">
        <p className="body-text font-bold text-white text-left">Skin Tone</p>
        <div className="flex justify-between gap-8 w-full h-auto ">
          <CustomSlider options={data.skinTone.options} />
        </div>
      </div>

      {/* HAIR */}
      <div className="flex flex-col w-full gap-2">
        <p className="body-text font-bold text-white text-left">Hair</p>
        <div className="flex flex-col gap-4">
          {/* Style */}
          <p className="smallheading-small text-gray4">Style</p>
          <div className="flex justify-between gap-8 w-full">
            {data.hair.style.map((item, index) => (
              <button
                key={index}
                className={` w-1/5 rounded-lg bg-overlayBlue border border-gray4`}
              >
                {isLoading ? (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    height={150}
                    className="rounded-lg"
                  />
                ) : (
                  <img src="h-20 w-full rounded-md " />
                )}
                <p className="py-2 px-4 text-white ">{item.label}</p>
              </button>
            ))}
          </div>

          {/* Texture */}
          <p className="smallheading-small text-gray4">Texture</p>
          <div className="flex justify-between gap-8 w-full">
            {data.hair.texture.map((item, index) => (
              <button
                key={index}
                className={` w-1/5 rounded-lg bg-overlayBlue border border-gray4`}
              >
                {isLoading ? (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100%"
                    height={150}
                    className="rounded-lg"
                  />
                ) : (
                  <img src="h-20 w-full rounded-md " />
                )}
                <p className="py-2 px-4 text-white ">{item.label}</p>
              </button>
            ))}
          </div>

          {/* COLOR */}
          <p className="smallheading-small text-gray4">Color</p>
          <div className="flex justify-between gap-8 w-full h-auto ">
            <CustomSlider options={data.hair.color.options} />
          </div>
        </div>
      </div>
    </div>
  );
};
