export const GENDER = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  // {
  //   value: "prefer not to say",
  //   label: "Prefer not to say",
  // },
];
