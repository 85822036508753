import {
  IoChatboxOutline,
  IoGlobe,
  IoHome,
  IoLogOut,
  IoPricetag,
} from "react-icons/io5";
import { MdCollectionsBookmark } from "react-icons/md";
import { FaHistory, FaRegUserCircle, FaTools } from "react-icons/fa";
import { FaRobot, FaTrophy } from "react-icons/fa6";
import { BsNewspaper } from "react-icons/bs";

export const SidebarMenuItems = [
  { label: "Home", icon: <IoHome size={"1.25rem"} color="white" />, link: "/" },
  {
    label: "History",
    icon: <FaHistory size={"1.25rem"} color="white" />,
    link: "/history",
  },
  {
    label: "AI Chat",
    icon: <IoChatboxOutline size={"1.25rem"} color="white" />,
    link: "/aichat/youai?model=Personalised%20Image",
  },
  {
    label: "Collections",
    icon: <MdCollectionsBookmark size={"1.25rem"} color="white" />,

    subItems: [
      { label: "All Collections", link: "/collection" },
      { label: "Create Image", link: "/collection/text2img" },
      { label: "Create Video", link: "/collection/text2video" },
      { label: "Create Speech", link: "/collection/text2audio" },
      { label: "Create Text", link: "/collection/text2text" },
      { label: "Create Music", link: "/collection/text2music" },
      // { label: "Edit Images", link: "/collection/text2video" },
      // { label: "Interpret Images", link: "/collection/text2video" },
    ],
  },
  {
    label: "AI Challenges",
    icon: <FaTrophy size={"1.25rem"} color="white" />,
    subItems: [
      // { label: "Create Challenge", link: "/aichallenge" },
      { label: "Participate", link: "/aichallenge" },
    ],
  },
  {
    label: "Social",
    icon: <IoGlobe size={"1.25rem"} color="white" />,
    subItems: [
      { label: "Feed", link: "/social" },
      { label: "Explore", link: "/social/explore" },
      { label: "Notifications", link: "/notification" },
      { label: "Messages", link: "/messaging" },
      { label: "Social Profile", link: "/social_profile/" },
      { label: "Reels", link: "/social_reels/" },
      // { label: "Upload" },
      { label: "Search", link: "/social/search" },
      { label: "Saved Posts", link: "/saveAssets" },
      { label: "Prompt Leaderboard", link: "/promptLeaderboard" },
    ],
  },
  {
    label: "AI News",
    icon: <BsNewspaper size={"1.25rem"} color="white" />,
    link: "/globalainews",
  },
  {
    label: "AI Tools",
    icon: <FaTools size={"1.25rem"} color="white" />,
    link: "/tools",
  },
  {
    label: "Avatars",
    icon: <FaRobot size={"1.25rem"} color="white" />,
    subItems: [
      // { label: "View My Avatars" },
      { label: "Create Avatar", link: "/createAvatar" },
      { label: "Avatar Store", link: "/avatarStore" },
    ],
  },
  {
    label: "Account",
    icon: <FaRegUserCircle size={"1.25rem"} color="white" />,
    subItems: [
      { label: "My Profile", link: "/profile" },
      { label: "Billing", link: "/profile" },
    ],
  },
  {
    label: "Pricing",
    icon: <IoPricetag size={"1.25rem"} color="white" />,
    link: "/subscription",
  },
  { label: "Sign Out", icon: <IoLogOut size={"1.25rem"} color="white" /> },
];

export const customizedMenuItems = (userName, isAuth) => {
  const updatedMenuItems = SidebarMenuItems.map((item) => {
    // Modify the "Social Profile" link if the "Social" menu is found
    if (item.label === "Social" && item.subItems) {
      item.subItems = item.subItems.map((subItem) => {
        if (subItem.label === "Social Profile" && userName) {
          subItem.link = `/social_profile/${userName}`;
        }
        return subItem;
      });
    }

    // Conditionally update "Sign Out" or "Sign In"
    if (item.label === "Sign Out") {
      if (isAuth) {
        item.label = "Sign Out";
        item.link = "/signout"; // Link to sign out action
      } else {
        item.label = "Sign In";
        item.link = "/signin"; // Link to sign in page
      }
    }

    return item;
  });

  return updatedMenuItems;
};
