import React from "react";

const TermsOfUse = () => {
  return (
    <div
      className="text-white p-8"
      style={{
        width: "100%",
      }}
    >
      <div className="mx-auto">
        {/* Main Title */}
        <header className="mb-12">
          <h1 className="text-4xl font-bold text-center">
            Terms of Use for Ideaverse by Tensorblue Technologies Private
            Limited
          </h1>
        </header>

        <div className="space-y-12">
          {/* Acceptance to Terms */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Acceptance of Terms</h2>
            <p className="text-gray-300">
              By creating an account or using the services provided by
              Ideaverse, users agree to abide by the terms outlined in our End
              User License Agreement (EULA) and this Privacy Policy. These terms
              are designed to ensure a safe, respectful, and constructive
              environment for all users. By using the platform, you signify your
              acceptance of these terms and acknowledge that any violation may
              result in account suspension or permanent termination, as
              described below.
            </p>
          </section>

          {/* Prohibited Content and Behavior */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              Prohibited Content and Behavior
            </h2>
            <p className="text-gray-300 mb-4">
              Ideaverse is committed to maintaining a positive and inclusive
              community. As part of this commitment, we have zero tolerance for
              objectionable content and abusive behavior. This includes, but is
              not limited to:
            </p>
            <ul className="space-y-2 text-gray-300 ml-6 list-disc">
              <li className="text-body-1 font-semibold">
                Objectionable Content
              </li>
              <p className="text-body-1">
                Content that promotes hate speech, violence, harassment, or
                discrimination based on race, ethnicity, national origin,
                religion, gender, sexual orientation, disability, or any other
                protected characteristic. Explicitly sexual, obscene, or
                pornographic material. Content promoting illegal activities or
                advocating for harm to oneself or others. Misinformation,
                malicious falsehoods, or deceptive practices.
              </p>
              <li className="text-body-1 font-semibold">Abusive Users</li>
              <p className="text-body-1">
                Behavior that includes harassment, bullying, threats, or
                intimidation of any user or group. Exploitation of the platform
                for spamming, phishing, or spreading malware. Attempts to
                circumvent platform rules or engage in activities designed to
                disrupt the service or harm the user experience.
              </p>
            </ul>
          </section>

          {/* How We Use Your Information */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              Enforcement of Terms
            </h2>
            <p className="text-gray-300 mb-4">
              To uphold the integrity of the Ideaverse platform:
            </p>
            <ul className="space-y-2 text-gray-300 ml-6 list-disc">
              <li className="text-body-1 font-semibold">Content Monitoring:</li>
              <p className="text-body-1">
                Ideaverse uses automated tools and manual review to identify and
                remove content that violates our policies. While we strive to
                maintain proactive moderation, we encourage users to report any
                objectionable content they encounter.
              </p>

              <li className="text-body-1 font-semibold">Reporting Abuses:</li>
              <p className="text-body-1">
                Users can report any abusive behavior or objectionable content
                directly through the platform. All reports are reviewed by our
                moderation team, and appropriate action will be taken promptly.
              </p>
              <li className="text-body-1 font-semibold">
                Penalties for Violations:
              </li>
              <p className="text-body-1">
                First Violation: A warning and removal of the objectionable
                content.
                <br />
                Repeated Violations: Temporary suspension of account access.
                <br />
                Severe or Ongoing Violations: Permanent account termination and,
                where necessary, notification to legal authorities.
                <br />
              </p>
            </ul>
          </section>

          {/* Intellectual Property Rights */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">User Responsibility</h2>
            <p className="text-gray-300">
              All users of Ideaverse are expected to:
              <li>
                Act respectfully and responsibly while engaging with the
                platform and its community.
              </li>
              <li>
                Avoid sharing or creating objectionable content as outlined in
                this policy.
              </li>
              <li>
                Abide by all local, national, and international laws governing
                their use of Ideaverse.
              </li>
              <li>
                Users who do not agree to these terms are prohibited from using
                the Ideaverse platform.
              </li>
            </p>
          </section>

          {/* Termination */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Right to Amend</h2>
            <p className="text-gray-300">
              Ideaverse reserves the right to amend these terms at any time to
              address evolving user needs, regulatory requirements, or
              technological advancements. Continued use of the platform
              constitutes agreement to any updated terms.
            </p>
          </section>
          {/* Agreement to Terms */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Agreement to Terms</h2>
            <p className="text-gray-300">
              By using our Platform, you agree to be bound by these Terms of Use
              and our Privacy Policy. If you do not agree to these Terms, you
              may not use the Platform.
            </p>
          </section>

          {/* Platform Use */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Platform Use</h2>
            <p className="text-gray-300 mb-4">
              You may use the Platform only for lawful purposes and in
              accordance with these Terms. You agree not to use the Platform:
            </p>
            <ul className="space-y-2 text-gray-300 ml-6 list-disc">
              <li>
                The right to access, update, or delete the information we have
                on you.
              </li>
              <li>
                In any way that violates any applicable national or
                international law or regulation.
              </li>
              <li>
                For the purpose of exploiting, harming, or attempting to exploit
                or harm minors in any way.
              </li>
              <li>
                To transmit, or procure the sending of, any advertising or
                promotional material, including any "junk mail", "chain letter,"
                "spam," or any other similar solicitation.
              </li>
              <li>
                To impersonate or attempt to impersonate the Company, a Company
                employee, another user, or any other person or entity.
              </li>
            </ul>
          </section>

          {/* How We Use Your Information */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              How We Use Your Information
            </h2>
            <p className="text-gray-300 mb-4">
              We use personal information collected via our Platform for a
              variety of business purposes described below:
            </p>
            <ul className="space-y-2 text-gray-300 ml-6 list-disc">
              <li>To facilitate account creation and the logon process.</li>
              <li>To manage user accounts.</li>
              <li>To send administrative information to you.</li>
              <li>
                To fulfil and manage your orders and your use of our Services.
              </li>
              <li>To respond to legal requests and prevent harm.</li>
              <li>
                For other business purposes such as data analysis, identifying
                usage trends, determining the effectiveness of our promotional
                campaigns, and to evaluate and improve our Platform, products,
                services, marketing, and your experience.
              </li>
            </ul>
          </section>

          {/* Intellectual Property Rights */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              Intellectual Property Rights
            </h2>
            <p className="text-gray-300">
              The Platform and its entire contents, features, and functionality
              (including but not limited to all information, software, text,
              displays, images, video, and audio, and the design, selection, and
              arrangement thereof) are owned by the Company, its licensors, or
              other providers of such material and are protected by
              international copyright, trademark, patent, trade secret, and
              other intellectual property or proprietary rights laws.
            </p>
          </section>

          {/* Termination */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Termination</h2>
            <p className="text-gray-300">
              We may terminate or suspend your account and bar access to the
              Platform immediately, without prior notice or liability, under our
              sole discretion, for any reason whatsoever and without limitation,
              including but not limited to a breach of the Terms.
            </p>
          </section>

          {/* Governing Law */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Governing Law</h2>
            <p className="text-gray-300">
              These Terms shall be governed and construed in accordance with the
              laws of [Your Country], without regard to its conflict of law
              provisions.
            </p>
          </section>

          {/* Changes to Terms */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Changes to Terms</h2>
            <p className="text-gray-300">
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. By continuing to access or use our
              Platform after any revisions become effective, you agree to be
              bound by the revised terms. If you do not agree to the new terms,
              you are no longer authorised to use the Platform.
            </p>
          </section>

          {/* Policies Section */}
          <div className="space-y-8">
            {/* Restricted Content */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">
                1. Restricted Content
              </h2>
              <div className="space-y-4 text-gray-300">
                <p>
                  Ideaverse does not feature content or services that endanger
                  children, strictly adhering to protections against
                  exploitation or harm.
                </p>
                <p>
                  The platform ensures all content is respectful and prohibits
                  material that is offensive, promotes hate, violence, or is
                  sexually explicit.
                </p>
                <p>
                  Ideaverse offers financial services with clarity and
                  transparency, fully compliant with applicable laws to guard
                  against fraud.
                </p>
                <p>
                  Ideaverse does not host or promote any real-money gambling or
                  contests, ensuring a safe and ethical environment for all
                  users.
                </p>
                <p>
                  The platform firmly stands against promoting or facilitating
                  illegal activities.
                </p>
                <p>
                  Ideaverse actively monitors, reviews, and removes harmful or
                  illegal user-generated content, maintaining a safe community
                  environment.
                </p>
                <p>
                  Ideaverse does not offer health-related content or services,
                  avoiding the risks of misinformation or inappropriate health
                  advice.
                </p>
                <p>
                  Ideaverse does not provide blockchain services or content,
                  focusing on technology and experiences outside of the
                  blockchain spectrum.
                </p>
                <p>
                  Ideaverse ensures AI-generated content is monitored to prevent
                  misinformation, impersonation, or misleading information.
                </p>
              </div>
            </section>

            {/* Impersonation Policy */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">
                2. Impersonation Policy
              </h2>
              <p className="text-gray-300">
                Ideaverse avoids any form of impersonation with clear app
                titles, icons, and descriptions, ensuring users are
                well-informed of the app's ownership and purpose.
              </p>
            </section>

            {/* Privacy Policy */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">
                4. Privacy, Deception, and Device Abuse Policy
              </h2>
              <div className="space-y-4 text-gray-300">
                <p>
                  Ideaverse ensures the secure and private handling of user
                  data, protecting it from unauthorized access.
                </p>
                <p>
                  The app requests only necessary permissions, upholding user
                  privacy and ensuring consent.
                </p>
                <p>
                  Ideaverse protects against harmful practices to user devices
                  or networks, maintaining a secure environment.
                </p>
                <p>
                  The platform is transparent in its functionality and data
                  collection, avoiding any deceptive practices.
                </p>
                <p>
                  Ideaverse accurately represents its features and services,
                  providing truthful information to users.
                </p>
              </div>
            </section>

            {/* Additional Policies */}
            {[
              {
                title: "5. Monetization and Ads Policy",
                content: [
                  "Transactions within Ideaverse are transparent and fair, using Google Play's payment systems.",
                  "Ads are displayed appropriately, focusing on enhancing user experience and complying with family-targeted programs.",
                ],
              },
              {
                title: "6. Store Listing and Promotion Policy",
                content: [
                  "Ideaverse offers accurate and clear app listings, steering clear of spammy promotions and prioritizing quality content.",
                ],
              },
              {
                title: "7. Spam and Minimum Functionality Policy",
                content: [
                  "Ideaverse provides meaningful functionality and value, ensuring a high-quality user experience.",
                ],
              },
              {
                title: "8. Malware Policy",
                content: [
                  "Ideaverse employs stringent security measures to prevent any form of malware, prioritizing the safety of users and their data.",
                ],
              },
              {
                title: "9. Mobile Unwanted Software (MUwS) Policy",
                content: [
                  "Ideaverse opposes ad fraud, system mimicry, or social engineering, creating a secure and trustworthy platform for users.",
                ],
              },
              {
                title: "10. Families Policy",
                content: [
                  "For apps targeting children, Ideaverse maintains a safe and appropriate environment, following Google Play's certification for ad SDKs.",
                ],
              },
            ].map((policy, index) => (
              <section key={index}>
                <h2 className="text-2xl font-semibold mb-4">{policy.title}</h2>
                <div className="space-y-4 text-gray-300">
                  {policy.content.map((text, i) => (
                    <p key={i}>{text}</p>
                  ))}
                </div>
              </section>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
