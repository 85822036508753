import React, { useState } from "react";
import MainLogo from "assets/mainLogo-transformed.webp";
import apiService from "services/apiService";
import baseUrl from "baseUrl";
import { toast } from "react-toastify";
import CircularProgressLoader from "./RandomProgressLoader";
import { Box, LinearProgress } from "@mui/material";

export const Step1 = ({ handleNextStep, setGeneratedImages }) => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);

  const handleImageUpload = async (e) => {
    setError("");
    const files = Array.from(e.target.files);

    try {
      const base64Images = await Promise.all(
        files?.map((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result); // Get Base64 string
            reader.onerror = (err) => reject(err);
            reader.readAsDataURL(file); // Read file as Base64
          });
        })
      );

      setImages(base64Images); // Store Base64 strings
    } catch (error) {
      console.error("Error converting images to Base64:", error);
      setError("Failed to process images.");
    }
  };

  const handleSubmitImages = async () => {
    if (images.length === 0) {
      setError("Please upload at least one image.");
      return;
    }
    setError(false);
    setUploading(true);

    try {
      const response = await apiService.post(`${baseUrl}/ai/onboarding`, {
        imageUrls: images,
      });
      if (response) {
        setGeneratedImages(response.images);
      }
      handleNextStep();
    } catch (error) {
      console.error("Error uploading images:", error);
      setError("An error occurred while uploading images.");
      toast.error("Sorry, something went wrong.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="md:h-[90%] w-full md:w-auto rounded-3xl border-2 border-[#8899A8]">
      <div className="flex p-6 xl:px-4 py-8 justify-center items-center flex-col w-full gap-8 h-full">
        <div className="w-full flex justify-center items-center gap-4">
          <div className="w-10 h-10">
            <img
              src={MainLogo}
              className="w-full h-full object-cover"
              alt="logo"
            />
          </div>
          <h3 className="text-lg font-bold text-white">Ideaverse.AI</h3>
        </div>
        <Box sx={{ width: "90%" }}>
          <LinearProgress color={"success"} variant="determinate" value={50} />
        </Box>
        <div className="flex gap-4 justify-center items-center flex-col">
          <p className="text-white text-center text-[17px]">
            Let's upload our photos!
          </p>
          <p className="text-white text-center text-xs">
            Upload 1 image with no glasses and clear forehead.
          </p>
        </div>
        <div
          className={`w-[85%] relative rounded-2xl cursor-pointer border-[1px] ${
            error ? "border-red-700" : "border-[#4BC25E]"
          } px-6 py-8 text-xs text-center text-[#4BC25E] bg-[#1B2333] h-[30vh] flex justify-center items-center md:h-auto`}
        >
          <input
            type="file"
            accept="image/*,.heic,.heif"
            title=""
            onChange={handleImageUpload}
            className="w-full opacity-0 h-full absolute top-0 left-0 cursor-pointer"
          />
          {images.length
            ? `${images.length} picture selected`
            : "Drop your picture here"}
        </div>
        <div className="w-full flex justify-center items-center">
          <button
            onClick={handleSubmitImages}
            className="w-[90%] text-[#1B2333] font-semibold bg-[#4BC25E] rounded-lg p-2 text-sm active:scale-95 transition-transform duration-150"
            disabled={uploading}
          >
            {uploading ? (
              <div className="flex gap-1 items-center justify-center">
                <CircularProgressLoader
                  duration={60000}
                  onComplete={() => {}}
                />
              </div>
            ) : (
              "Submit Images"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
