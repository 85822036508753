import { useEffect } from "react";

const useViewportHeightFix = () => {
  useEffect(() => {
    const updateViewportHeight = () => {
      // Calculate the actual viewport height and set it as a CSS variable
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    updateViewportHeight(); // Set initially

    window.addEventListener("resize", updateViewportHeight);
    return () => {
      window.removeEventListener("resize", updateViewportHeight);
    };
  }, []);
};

export default useViewportHeightFix;
