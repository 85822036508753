import React from "react";

export const Step4 = ({ handleNextStep }) => {
  const videoUrl =
    "https://mygenerateddatabucket.s3.eu-north-1.amazonaws.com/Social/compressed_1734544466475.mp4";
  // normal url: https://mygenerateddatabucket.s3.eu-north-1.amazonaws.com/Social/Video_udit_1733566943908.mp4
  return (
    <div className="flex flex-col justify-between items-center w-full h-full p-4 overflow-hidden">
      <div className="relative w-full h-[90%]">
        <video
          src={videoUrl}
          className="w-full h-full object-cover rounded-lg"
          controls={false}
          autoPlay
          loop
          muted
        >
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Next Button */}
      <div className="w-full flex justify-center">
        <button
          className="w-1/2 mt-4 text-[#1B2333] font-semibold bg-[#4BC25E] rounded-lg p-3 text-sm active:scale-95 transition-transform duration-150"
          onClick={handleNextStep}
        >
          Generate Images in Any Scene
        </button>
      </div>
    </div>
  );
};
