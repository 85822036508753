import React from "react";
import RegisterModalV2 from "./components/RegisterModalV2";
import { setDisplayRegister } from "../../features/userSlice";
import "./RegisterModal.css";
import { useDispatch } from "react-redux";
import RegisterMobile from "./components/RegisterMobile";
import useIsMobile from "hooks/useIsMobile";

const RegisterModal = ({ customMessage, isOpen = true }) => {
  const dispatch = useDispatch();
  const isMobileScreen = useIsMobile();

  return (
    <div>
      {isMobileScreen ? (
        <RegisterMobile
          isOpen={isOpen}
          customMessage={customMessage}
          onRequestClose={() => dispatch(setDisplayRegister(false))}
        />
      ) : (
        <RegisterModalV2
          isOpen={isOpen}
          customMessage={customMessage}
          onRequestClose={() => dispatch(setDisplayRegister(false))}
        />
      )}
    </div>
  );
};

export default RegisterModal;
