import mixpanel from "mixpanel-browser";

// Initialize Mixpanel with your project token
mixpanel.init("3e9d43eeb4328d77e9410c7f860f5228", { debug: true });

// Function to track session start
export function trackSessionStart(userId) {
  console.log("Session Start: User ID", userId); // Log when session starts

  // Track time between sessions
  const timeBetweenSessions = trackTimeBetweenSessions(userId);

  // Get current date and time
  const now = new Date();
  const startTime = now.toISOString();

  // Get the time of day and day of the week
  const timeOfDay = now.getHours(); // Returns the hour (0-23)
  const dayOfWeek = now.getDay(); // Returns the day of the week (0 = Sunday, 6 = Saturday)

  // Track consecutive day returns
  trackConsecutiveDays(userId);
  trackTimeBasedRetention(userId);
  trackFirstWeekSessionAverage(userId);

  // Track session start with Mixpanel
  mixpanel.track("Session Start", {
    "User ID": userId,
    "Start Time": startTime,
    "Page URL": window.location.href,
    "Time Between Sessions (ms)": timeBetweenSessions || "First Session",
    "Time of Day": timeOfDay, // Add time of day (in hours)
    "Day of Week": dayOfWeek, // Add day of the week (0 = Sunday, 6 = Saturday)
  });

  // Store session start time in localStorage
  localStorage.setItem("session_start_time", Date.now());
}

// Function to track session end
export function trackSessionEnd(userId) {
  const sessionStartTime = localStorage.getItem("session_start_time");
  if (sessionStartTime) {
    const sessionDuration = Date.now() - sessionStartTime;

    console.log("Session End: User ID", userId); // Log when session ends
    console.log("Session Duration (ms):", sessionDuration); // Log the session duration

    const now = new Date();
    const endTime = now.toISOString();
    const timeOfDay = now.getHours();
    const dayOfWeek = now.getDay();

    // Track session end with Mixpanel
    mixpanel.track("Session End", {
      "User ID": userId,
      "End Time": endTime,
      "Session Duration (ms)": sessionDuration,
      "Page URL": window.location.href,
      "Time of Day": timeOfDay, // Add time of day (in hours)
      "Day of Week": dayOfWeek, // Add day of the week (0 = Sunday, 6 = Saturday)
    });

    // Store session duration for retention analysis
    storeSessionDuration(userId, sessionDuration);

    // Track session duration change for retained users
    trackSessionDurationChangeForRetainedUsers(userId);

    // Update last session date to today
    const today = new Date().toISOString(); // Store as ISO string for better comparison
    localStorage.setItem(`last_return_date_${userId}`, today); // Store last return date for each user

    // Store session end time for calculating time between sessions
    localStorage.setItem("last_session_end_time", Date.now());

    // Clear session start time from localStorage
    localStorage.removeItem("session_start_time");

    // Check 7-day retention status for the user
    calculateAverageActions(userId);
    checkSevenDayRetention(userId);
    checkThirtyDayRetention(userId);
    checkLongTermUserRetention(userId);
    trackExitPoint("Page Close", userId);
  }
}

export function trackUserLogin(userId) {
  console.log("User Login: User ID", userId);

  // Track the "User Login" event
  mixpanel.track("User Login", {
    "User ID": userId,
    "Login Time": new Date().toISOString(),
  });

  // Identify the user
  mixpanel.identify(userId);

  // Retrieve last login date and consecutive login days from local storage
  const lastLoginDate = localStorage.getItem("lastLoginDate");
  const consecutiveLoginDays =
    parseInt(localStorage.getItem("consecutiveLoginDays")) || 0;

  if (lastLoginDate) {
    // Call trackConsecutiveLogins if lastLoginDate exists
    trackConsecutiveLogins(userId, lastLoginDate, consecutiveLoginDays);
  } else {
    // If no previous login date is available, set consecutive_login_days to 1
    localStorage.setItem("lastLoginDate", new Date().toISOString());
    localStorage.setItem("consecutiveLoginDays", "1");

    // Also set it in Mixpanel
    mixpanel.people.set({
      $last_login: new Date().toISOString(),
      consecutive_login_days: 1,
    });
  }
}

function trackConsecutiveLogins(userId, lastLoginDate, consecutiveLoginDays) {
  const currentDate = new Date();
  const lastLogin = new Date(lastLoginDate);

  // Calculate the difference in days between the current login and the last login
  const differenceInTime = currentDate.getTime() - lastLogin.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  if (differenceInDays === 1) {
    // If the user logged in the next day, increase consecutive login count
    const newConsecutiveLoginDays = consecutiveLoginDays + 1;
    localStorage.setItem(
      "consecutiveLoginDays",
      newConsecutiveLoginDays.toString()
    );

    // Update Mixpanel with the new count
    mixpanel.people.increment("consecutive_login_days");

    // Check for a 3-day login streak
    if (newConsecutiveLoginDays === 3) {
      // Track the 3-day streak in Mixpanel
      mixpanel.track("3-Day Login Streak", {
        "User ID": userId,
        "Streak Days": newConsecutiveLoginDays,
        "Streak Date": currentDate.toISOString(),
      });

      console.log(`User ${userId} has logged in 3 days in a row!`);
    }
  } else {
    // If more than a day has passed, reset consecutive login days
    localStorage.setItem("consecutiveLoginDays", "1");

    // Update Mixpanel with the reset count
    mixpanel.people.set("consecutive_login_days", 1);
  }

  // Update last login date in local storage and Mixpanel
  localStorage.setItem("lastLoginDate", currentDate.toISOString());
  mixpanel.people.set({
    $last_login: currentDate.toISOString(),
  });
}

// Function to check consecutive day returns
export function trackConsecutiveDays(userId) {
  const lastSessionDate = localStorage.getItem("last_session_date");
  console.log("Last Session Date:", lastSessionDate, "User ID:", userId);
  const today = new Date().toDateString(); // Get today's date as a string

  if (lastSessionDate) {
    const daysSinceLastSession =
      (new Date(today) - new Date(lastSessionDate)) / (1000 * 60 * 60 * 24);
    console.log(
      "Days Since Last Session:",
      daysSinceLastSession,
      "User ID:",
      userId
    );

    // Track consecutive day return if user returns after 2 days
    if (daysSinceLastSession === 1) {
      console.log("Tracking 2 consecutive days return"); // Log tracking event
      mixpanel.track("Consecutive 2 Day Return", {
        "User ID": userId,
        "Consecutive Days": 2,
      });
    }
    if (daysSinceLastSession === 2) {
      console.log("Tracking 3 consecutive days return"); // Log tracking event
      mixpanel.track("Consecutive 3  Day Return", {
        "User ID": userId,
        "Consecutive Days": 3,
      });
    }
    if (daysSinceLastSession === 7) {
      console.log("Tracking 1 week return"); // Log tracking event
      mixpanel.track("Consecutive Week Return", {
        "User ID": userId,
        "Consecutive Days": 7,
      });
    }
  }

  // Update last session date
  localStorage.setItem("last_session_date", today);
}

// Function to calculate time between sessions
function trackTimeBetweenSessions(userId) {
  const lastSessionEndTime = localStorage.getItem("last_session_end_time");
  if (lastSessionEndTime) {
    const timeBetweenSessions = Date.now() - lastSessionEndTime;
    console.log("Time Between Sessions (ms):", timeBetweenSessions);
    return timeBetweenSessions;
  }
  return null; // Return null if no previous session is found
}

export function checkSevenDayRetention(userId) {
  const firstVisitDate = localStorage.getItem(`first_visit_date_7d_${userId}`);
  const retentionTracked = localStorage.getItem(
    `retention_tracked_7d_${userId}`
  );

  // First visit handling
  if (!firstVisitDate) {
    const currentTime = Date.now();
    localStorage.setItem(
      `first_visit_date_7d_${userId}`,
      currentTime.toString()
    );

    mixpanel.track("User First Visit 7D", {
      "User ID": userId,
      "Visit Date": new Date(currentTime).toISOString(),
    });

    return;
  }

  const firstVisitTime = parseInt(firstVisitDate);
  const currentTime = Date.now();
  const daysSinceFirstVisit = Math.floor(
    (currentTime - firstVisitTime) / (24 * 60 * 60 * 1000)
  );
  const sevenDaysAfterFirstVisit = firstVisitTime + 7 * 24 * 60 * 60 * 1000;

  // Track return visit (separate from retention)
  mixpanel.track("User Return Visit 7D", {
    "User ID": userId,
    "Days Since First Visit": daysSinceFirstVisit,
    "Visit Date": new Date(currentTime).toISOString(),
  });

  // Handle 7-day retention tracking
  if (
    !retentionTracked &&
    daysSinceFirstVisit <= 7 &&
    daysSinceFirstVisit > 0
  ) {
    // User returned within 7 days
    mixpanel.track("7-Day Retention", {
      "User ID": userId,
      "Returned Within 7 Days": true,
      "Days Until Return": daysSinceFirstVisit,
      "Return Date": new Date(currentTime).toISOString(),
      "First Visit Date": new Date(firstVisitTime).toISOString(),
    });
    localStorage.setItem(`retention_tracked_7d_${userId}`, "true");
  } else if (!retentionTracked && currentTime > sevenDaysAfterFirstVisit) {
    // 7 days passed without return
    mixpanel.track("7-Day Retention", {
      "User ID": userId,
      "Returned Within 7 Days": false,
      "Days Since First Visit": daysSinceFirstVisit,
      "First Visit Date": new Date(firstVisitTime).toISOString(),
    });
    localStorage.setItem(`retention_tracked_7d_${userId}`, "true");
  }

  // Update last return date for future reference
  localStorage.setItem(
    `last_return_date_7d_${userId}`,
    new Date(currentTime).toISOString()
  );
}

export function checkThirtyDayRetention(userId) {
  const firstVisitDate = localStorage.getItem(`first_visit_date_${userId}`);
  const retentionTracked = localStorage.getItem(`retention_tracked_${userId}`);

  // First visit handling
  if (!firstVisitDate) {
    const currentTime = Date.now();
    localStorage.setItem(`first_visit_date_${userId}`, currentTime.toString());

    mixpanel.track("User First Visit", {
      "User ID": userId,
      "Visit Date": new Date(currentTime).toISOString(),
    });

    return;
  }

  const firstVisitTime = parseInt(firstVisitDate);
  const currentTime = Date.now();
  const daysSinceFirstVisit = Math.floor(
    (currentTime - firstVisitTime) / (24 * 60 * 60 * 1000)
  );
  const thirtyDaysAfterFirstVisit = firstVisitTime + 30 * 24 * 60 * 60 * 1000;

  // Track return visit (separate from retention)
  mixpanel.track("User Return Visit", {
    "User ID": userId,
    "Days Since First Visit": daysSinceFirstVisit,
    "Visit Date": new Date(currentTime).toISOString(),
  });

  // Handle 30-day retention tracking
  if (
    !retentionTracked &&
    daysSinceFirstVisit <= 30 &&
    daysSinceFirstVisit > 0
  ) {
    // User returned within 30 days
    mixpanel.track("30-Day Retention", {
      "User ID": userId,
      "Returned Within 30 Days": true,
      "Days Until Return": daysSinceFirstVisit,
    });
    localStorage.setItem(`retention_tracked_${userId}`, "true");
  } else if (!retentionTracked && currentTime > thirtyDaysAfterFirstVisit) {
    // 30 days passed without return
    mixpanel.track("30-Day Retention", {
      "User ID": userId,
      "Returned Within 30 Days": false,
      "Days Since First Visit": daysSinceFirstVisit,
    });
    localStorage.setItem(`retention_tracked_${userId}`, "true");
  }
}

export function checkUserReturnAfterInactivity(userId) {
  if (!userId) {
    console.error("User ID is required");
    return;
  }

  const lastLoginKey = `last_login_date_${userId}`;
  const lastLoginDate = localStorage.getItem(lastLoginKey);
  const currentDate = new Date();

  console.log("Last Login Date:", lastLoginDate, "User ID:", userId);

  if (lastLoginDate) {
    try {
      const lastLoginTime = new Date(lastLoginDate).getTime();
      const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;
      const oneWeekAgo = currentDate.getTime() - oneWeekInMs;

      // Check if the last login date is older than one week
      if (lastLoginTime < oneWeekAgo) {
        const inactiveDays = Math.floor(
          (currentDate.getTime() - lastLoginTime) / (24 * 60 * 60 * 1000)
        );

        console.log(
          `User ${userId} returned after ${inactiveDays} days of inactivity`
        );

        mixpanel.track("Return After Inactivity", {
          "User ID": userId,
          "Inactive Period (days)": inactiveDays,
          "Last Login Date": new Date(lastLoginTime).toISOString(),
          "Return Date": currentDate.toISOString(),
        });
      }
    } catch (error) {
      console.error("Error processing date:", error);
    }
  }

  // Update the last login date
  localStorage.setItem(lastLoginKey, currentDate.toISOString());
}

// Function to track page navigation
export function trackPageNavigation(userId) {
  console.log("Page Navigation: User ID", userId);

  // Get the current session action count
  const currentActionCount =
    parseInt(localStorage.getItem(`action_count_${userId}`)) || 0;

  // Increment the action count for the current session
  const newActionCount = currentActionCount + 1;

  // Store the updated action count in localStorage
  localStorage.setItem(`action_count_${userId}`, newActionCount);

  // Track the navigation event in Mixpanel
  mixpanel.track("Page Navigation", {
    "User ID": userId,
    "New Action Count": newActionCount,
    "Page URL": window.location.href,
    "Navigation Time": new Date().toISOString(),
  });
}

export function calculateAverageActions(userId) {
  // Get the total number of actions from localStorage
  const totalActions =
    parseInt(localStorage.getItem(`action_count_${userId}`)) || 0;

  // You may want to keep track of total sessions as well
  const totalSessions =
    parseInt(localStorage.getItem(`total_sessions_${userId}`)) || 1;

  // Calculate average actions per session
  const averageActions = totalActions / totalSessions;

  console.log(
    `Average Actions per Session for User ${userId}:`,
    averageActions
  );

  // Optionally track this average in Mixpanel
  mixpanel.track("Average Actions Per Session", {
    "User ID": userId,
    "Average Actions": averageActions,
  });

  return averageActions;
}

const trackFirstActions = (userId) => {
  const loginTimestamp = localStorage.getItem(`login_timestamp_${userId}`);
  if (loginTimestamp) {
    const currentTime = new Date().getTime();
    const timeToFirstAction = currentTime - parseInt(loginTimestamp);

    // Track the "Time to First Action" event in Mixpanel
    mixpanel.track("Time to First Action", {
      "User ID": userId,
      "Time to First Action (ms)": timeToFirstAction,
    });

    // Clear login timestamp to ensure this is only tracked for the first action after login
    localStorage.removeItem(`login_timestamp_${userId}`);
  }
};

export const onPageNavigation = (userId) => {
  // Check if this is the first action
  if (localStorage.getItem(`login_timestamp_${userId}`)) {
    trackFirstActions(userId);
  }

  // Log the navigation event
  mixpanel.track("Page Navigation", {
    "User ID": userId,
    "Navigation Time": new Date().toISOString(),
  });
};

export function AverageTrackTimeBetweenSessions(userId) {
  const lastSessionEndTime = localStorage.getItem("last_session_end_time");

  if (lastSessionEndTime) {
    const timeSinceLastSession = Date.now() - parseInt(lastSessionEndTime);
    console.log("Time Between Sessions (ms):", timeSinceLastSession); // Log the time between sessions

    // Track the average time between sessions in Mixpanel
    mixpanel.track("Average Time Between Sessions", {
      "User ID": userId,
      "Time Between Sessions (ms)": timeSinceLastSession,
    });

    // Optional: Store the time to calculate average over multiple sessions
    const totalTimeBetweenSessions =
      parseInt(localStorage.getItem(`total_time_between_sessions_${userId}`)) ||
      0;
    const totalSessions =
      parseInt(localStorage.getItem(`total_sessions_${userId}`)) || 0;

    // Update the stored total time and increment session count
    localStorage.setItem(
      `total_time_between_sessions_${userId}`,
      totalTimeBetweenSessions + timeSinceLastSession
    );
    localStorage.setItem(`total_sessions_${userId}`, totalSessions + 1);
  }
}

// Function to track session duration change over time for retained users
function trackSessionDurationChangeForRetainedUsers(userId) {
  // Retrieve the stored session durations for this user
  const sessionDurations =
    JSON.parse(localStorage.getItem(`session_durations_${userId}`)) || [];

  // Check if the user is retained (has more than one session)
  if (sessionDurations.length < 2) {
    console.log(
      `User ${userId} does not have enough sessions to track retention-based trends.`
    );
    return;
  }

  // Calculate average session duration over time
  const totalDuration = sessionDurations.reduce(
    (sum, session) => sum + session.duration,
    0
  );
  const averageSessionDuration = totalDuration / sessionDurations.length;

  // Calculate the change in session duration over time
  const initialSessionDuration = sessionDurations[0].duration;
  const latestSessionDuration =
    sessionDurations[sessionDurations.length - 1].duration;
  const durationChange = latestSessionDuration - initialSessionDuration;

  // Track the trend with Mixpanel
  mixpanel.track("Session Duration Change for Retained Users", {
    "User ID": userId,
    "Average Session Duration (ms)": averageSessionDuration,
    "Initial Session Duration (ms)": initialSessionDuration,
    "Latest Session Duration (ms)": latestSessionDuration,
    "Session Duration Change (ms)": durationChange,
    "Total Sessions": sessionDurations.length,
  });

  console.log(
    `Tracked session duration change for user ${userId}. Average: ${averageSessionDuration}, Change: ${durationChange}`
  );
}

function storeSessionDuration(userId, sessionDuration) {
  const sessionDurations =
    JSON.parse(localStorage.getItem(`session_durations_${userId}`)) || [];
  sessionDurations.push({
    date: new Date().toISOString(),
    duration: sessionDuration,
  });
  localStorage.setItem(
    `session_durations_${userId}`,
    JSON.stringify(sessionDurations)
  );
}

export function trackExitPoint(exitType, userId) {
  const exitTime = new Date().toISOString();

  // Track exit point with Mixpanel
  mixpanel.track("Exit Point", {
    "User ID": userId,
    "Exit Type": exitType,
    "Exit Time": exitTime,
    "Page URL": window.location.href,
  });

  console.log(`User ${userId} exited the app via ${exitType} at ${exitTime}`);
}

// Helper function to get user ID (replace with your logic)
export function getUserId(userId) {
  // Implement your logic to retrieve the logged-in user's ID
  return userId; // Replace this with the actual user ID
}

// Idle time detection to automatically end session after inactivity
let idleTimeout;
export function resetIdleTimer(userId) {
  clearTimeout(idleTimeout);
  idleTimeout = setTimeout(
    () => {
      console.log("Idle time exceeded, ending session."); // Log idle timeout
      trackSessionEnd(userId); // Call trackSessionEnd() with userId when ending the session
    },
    30 * 60 * 1000
  ); // 30 minutes of inactivity
}

// new function integrate
// Function to track and analyze retention by time and day patterns
export function trackTimeBasedRetention(userId) {
  const lastReturnDate = localStorage.getItem(`last_return_date_${userId}`);
  const lastReturnTime = localStorage.getItem(`last_return_time_${userId}`);

  if (lastReturnDate && lastReturnTime) {
    const now = new Date();
    const lastReturn = new Date(lastReturnDate);
    const lastReturnHour = new Date(parseInt(lastReturnTime)).getHours();

    // Get current time details
    const currentHour = now.getHours();
    const currentDayOfWeek = now.getDay();

    // Define time blocks (4-hour intervals)
    const timeBlock = Math.floor(currentHour / 4);
    const timeBlocks = [
      "Early Morning (12AM-4AM)",
      "Morning (4AM-8AM)",
      "Mid Morning (8AM-12PM)",
      "Afternoon (12PM-4PM)",
      "Evening (4PM-8PM)",
      "Night (8PM-12AM)",
    ];

    // Define day names for better readability
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Calculate days between visits
    const daysBetweenVisits = Math.floor(
      (now - lastReturn) / (1000 * 60 * 60 * 24)
    );

    // Track retention with time and day information
    mixpanel.track("Time Based Retention", {
      "User ID": userId,
      "Day of Week": dayNames[currentDayOfWeek],
      "Time Block": timeBlocks[timeBlock],
      "Hour of Day": currentHour,
      "Days Between Visits": daysBetweenVisits,
      "Previous Visit Day": dayNames[lastReturn.getDay()],
      "Previous Visit Time Block": timeBlocks[Math.floor(lastReturnHour / 4)],
      "Is Same Day Part": Math.floor(lastReturnHour / 4) === timeBlock,
      "Is Same Day": currentDayOfWeek === lastReturn.getDay(),
      "Retention Type": categorizeRetention(daysBetweenVisits),
    });

    // Track specific patterns
    trackRetentionPatterns(userId, {
      currentDayOfWeek,
      currentHour,
      lastReturnDay: lastReturn.getDay(),
      lastReturnHour,
      daysBetweenVisits,
    });
  }

  // Store current visit time information
  localStorage.setItem(`last_return_date_${userId}`, new Date().toISOString());
  localStorage.setItem(`last_return_time_${userId}`, Date.now().toString());
}

// Helper function to categorize retention
function categorizeRetention(daysBetweenVisits) {
  if (daysBetweenVisits === 0) return "Same Day Return";
  if (daysBetweenVisits === 1) return "Next Day Return";
  if (daysBetweenVisits <= 7) return "Week Return";
  if (daysBetweenVisits <= 30) return "Month Return";
  return "Long Term Return";
}

// Helper function to track specific retention patterns
function trackRetentionPatterns(userId, data) {
  const {
    currentDayOfWeek,
    currentHour,
    lastReturnDay,
    lastReturnHour,
    daysBetweenVisits,
  } = data;

  // Track if user has a consistent time of day pattern
  if (Math.abs(currentHour - lastReturnHour) <= 2) {
    mixpanel.track("Consistent Time Pattern", {
      "User ID": userId,
      "Preferred Hour": currentHour,
      "Visit Pattern": "Same Time",
      "Days Between Visits": daysBetweenVisits,
    });
  }

  // Track if user has a consistent day of week pattern
  if (currentDayOfWeek === lastReturnDay) {
    mixpanel.track("Consistent Day Pattern", {
      "User ID": userId,
      "Preferred Day": currentDayOfWeek,
      "Visit Pattern": "Same Day of Week",
      "Days Between Visits": daysBetweenVisits,
    });
  }

  // Track if user is a weekend or weekday user
  const isWeekend = currentDayOfWeek === 0 || currentDayOfWeek === 6;
  mixpanel.track("Day Type Usage", {
    "User ID": userId,
    "Usage Type": isWeekend ? "Weekend User" : "Weekday User",
    "Day of Week": currentDayOfWeek,
    "Hour of Day": currentHour,
  });
}

export function trackFirstWeekSessionAverage(userId) {
  if (!userId) return;

  const signUpDateKey = `sign_up_date_${userId}`;
  const weekSessionCountKey = `first_week_sessions_${userId}`;
  const lastSessionKey = `last_session_${userId}`;

  const signUpDateStr = localStorage.getItem(signUpDateKey);
  const currentDate = new Date();

  if (!signUpDateStr) return;

  try {
    const signUpDate = new Date(signUpDateStr);
    const timeSinceSignUp = currentDate.getTime() - signUpDate.getTime();
    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;
    const isWithinFirstWeek = timeSinceSignUp <= oneWeekInMillis;

    // Check if this is a new session (30-minute gap)
    const lastSessionTime = localStorage.getItem(lastSessionKey);
    const isNewSession =
      !lastSessionTime ||
      currentDate.getTime() - new Date(lastSessionTime).getTime() >
        30 * 60 * 1000;

    if (isWithinFirstWeek && isNewSession) {
      // Increment session count
      const currentCount =
        parseInt(localStorage.getItem(weekSessionCountKey)) || 0;
      const newCount = currentCount + 1;
      localStorage.setItem(weekSessionCountKey, newCount);
      localStorage.setItem(lastSessionKey, currentDate.toISOString());

      // Calculate days elapsed for average
      const daysElapsed = Math.ceil(timeSinceSignUp / (24 * 60 * 60 * 1000));

      // Track each session with running average
      mixpanel.track("First Week Session", {
        "User ID": userId,
        "Session Count": newCount,
        "Days Elapsed": daysElapsed,
        "Average Sessions Per Day": (newCount / daysElapsed).toFixed(2),
        "Time Since Signup (hours)": Math.floor(
          timeSinceSignUp / (60 * 60 * 1000)
        ),
      });
    }

    // When first week completes, send final average
    if (timeSinceSignUp > oneWeekInMillis && isNewSession) {
      const totalSessions =
        parseInt(localStorage.getItem(weekSessionCountKey)) || 0;

      mixpanel.track("First Week Complete", {
        "User ID": userId,
        "Total Sessions": totalSessions,
        "Average Sessions Per Day": (totalSessions / 7).toFixed(2),
        "Week Start": signUpDate.toISOString(),
        "Week End": new Date(
          signUpDate.getTime() + oneWeekInMillis
        ).toISOString(),
      });
    }
  } catch (error) {
    console.error("Error tracking first week sessions:", error);
  }
}

export function trackOneTimeUsers(userId) {
  if (!userId) return;

  const firstLoginKey = `first_login_${userId}`;
  const lastLoginKey = `last_login_${userId}`;
  const loginCountKey = `login_count_${userId}`;
  const currentDate = new Date();

  try {
    // Get stored login data
    const firstLoginDate = localStorage.getItem(firstLoginKey);
    const lastLoginDate = localStorage.getItem(lastLoginKey);
    let loginCount = parseInt(localStorage.getItem(loginCountKey)) || 0;

    // If first login
    if (!firstLoginDate) {
      localStorage.setItem(firstLoginKey, currentDate.toISOString());
      localStorage.setItem(loginCountKey, "1");

      mixpanel.track("User First Login", {
        "User ID": userId,
        "First Login Date": currentDate.toISOString(),
        "Login Count": 1,
      });
      return;
    }

    // Calculate time since first login
    const timeSinceFirstLogin =
      currentDate.getTime() - new Date(firstLoginDate).getTime();
    const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000;

    // Increment login count
    loginCount++;
    localStorage.setItem(loginCountKey, loginCount.toString());
    localStorage.setItem(lastLoginKey, currentDate.toISOString());

    // If it's their second login, they're not a one-time user
    if (loginCount === 2) {
      mixpanel.track("Retained User", {
        "User ID": userId,
        "Days Until Return": Math.floor(
          timeSinceFirstLogin / (24 * 60 * 60 * 1000)
        ),
        "Is One Time User": false,
      });
    }

    // Check for one-time users after 30 days
    if (timeSinceFirstLogin >= thirtyDaysInMillis && loginCount === 1) {
      mixpanel.track("One Time User Confirmed", {
        "User ID": userId,
        "First Login Date": firstLoginDate,
        "Days Since First Login": Math.floor(
          timeSinceFirstLogin / (24 * 60 * 60 * 1000)
        ),
        "Is One Time User": true,
      });
    }
  } catch (error) {
    console.error("Error tracking one-time users:", error);
  }
}

export function trackWeekInactivityReturn(userId) {
  if (!userId) return;

  const lastActiveKey = `last_active_${userId}`;
  const currentDate = new Date();

  try {
    const lastActiveDate = localStorage.getItem(lastActiveKey);

    if (lastActiveDate) {
      const timeSinceLastActive =
        currentDate.getTime() - new Date(lastActiveDate).getTime();
      const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000;

      // Check if user was inactive for a week or more
      if (timeSinceLastActive >= oneWeekInMillis) {
        const inactiveDays = Math.floor(
          timeSinceLastActive / (24 * 60 * 60 * 1000)
        );

        mixpanel.track("Week Inactivity Return", {
          "User ID": userId,
          "Days Inactive": inactiveDays,
          "Last Active Date": lastActiveDate,
          "Return Date": currentDate.toISOString(),
        });
      }
    }

    // Update last active timestamp
    localStorage.setItem(lastActiveKey, currentDate.toISOString());
  } catch (error) {
    console.error("Error tracking week inactivity return:", error);
  }
}

export function checkLongTermUserRetention(userId) {
  const lastActivityDate = localStorage.getItem(`last_activity_date_${userId}`);
  console.log("Last Activity Date:", lastActivityDate, "User ID:", userId);

  if (lastActivityDate) {
    const lastActivityTime = new Date(lastActivityDate).getTime();
    const ninetyDaysAgo = Date.now() - 90 * 24 * 60 * 60 * 1000; // Timestamp for 90 days ago

    // Check if the last activity date is older than 90 days
    if (lastActivityTime < ninetyDaysAgo) {
      console.log("User has been active for more than 90 days:", userId);
      // Track the retention
      mixpanel.track("Long-Term User Retention", {
        "User ID": userId,
        "Active for More Than 90 Days": true,
      });
    } else {
      console.log("User has not been active for more than 90 days:", userId);
    }
  } else {
    console.log("No activity date found for user:", userId);
  }
}

// Function to check retention rate for new users
export function checkNewUserRetentionSignUp(userId) {
  const signUpDate = localStorage.getItem(`sign_up_date_${userId}`);
  console.log("Sign-Up Date:", signUpDate, "User ID:", userId);

  if (signUpDate) {
    const signUpTime = new Date(signUpDate).getTime();
    const thirtyDaysAgo = Date.now() - 30 * 24 * 60 * 60 * 1000; // Timestamp for 30 days ago

    // Check if the user signed up within the last 30 days
    if (signUpTime >= thirtyDaysAgo) {
      console.log("User is a new user:", userId);

      const lastReturnDate = localStorage.getItem(`last_return_date_${userId}`);
      if (lastReturnDate) {
        const lastReturnTime = new Date(lastReturnDate).getTime();

        // Check if the last return date is within 30 days from sign up
        if (lastReturnTime >= signUpTime) {
          console.log("New user returned within 30 days:", userId);
          // Track the new user retention
          mixpanel.track("New User Retention", {
            "User ID": userId,
            "Returned Within 30 Days": true,
          });
        } else {
          console.log("New user did not return within 30 days:", userId);
        }
      } else {
        console.log("No return date found for new user:", userId);
      }
    }
  } else {
    console.log("No sign-up date found for user:", userId);
  }
}

export function trackPageView(path) {
  try {
    // Normalize the path to ensure consistent tracking
    const normalizedPath = normalizePath(path);
    // Track the page view
    mixpanel.track("Page Views", {
      "Page Path": normalizedPath,
      Timestamp: new Date().toISOString(),
      "Hour of Day": new Date().getHours(),
      "Day of Week": new Date().getDay(),
    });
  } catch (error) {
    console.error("Error tracking page view:", error);
  }
}

// Helper function to normalize paths
function normalizePath(path) {
  return (
    path
      .toLowerCase()
      .replace(/\/+$/, "") // Remove trailing slashes
      .replace(/^\/*/, "/") || // Ensure single leading slash
    "/"
  ); // Default to root if empty
}

// Attach event listeners for idle time detection
document.addEventListener("mousemove", () => resetIdleTimer());
document.addEventListener("keypress", () => resetIdleTimer());
