import React from "react";
import PropTypes from "prop-types";

export const Dropdown = ({
  label,
  value,
  onChange,
  options = [],
  error,
  disabled = false,
  className = "",
  labelClassName = "",
  dropdownClassName = "",
  name = "",
}) => {
  const dropdownStyle = {
    width: "100%",
    maxWidth: "100%",
    paddingRight: "20px",
    boxSizing: "border-box",
  };

  return (
    <div className={`flex flex-col w-full ${className}`}>
      {label && (
        <label
          className={`mb-2 text-sm font-medium text-white ${labelClassName}`}
          htmlFor={label}
        >
          {label}
        </label>
      )}
      <select
        id={label}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`text-white font-semibold font-helvetica border-0 placeholder:text-gray4 text-sm rounded-md p-4 bg-overlayBlue focus:outline-none ${dropdownClassName} ${error ? "border border-red-500" : ""}`}
        style={dropdownStyle} // Apply styles here
      >
        <option
          className="bg-overlayBlue text-white font-semibold font-helvetica"
          value=""
          disabled
        >
          Select an option
        </option>
        {options.map((option) => (
          <option
            className="bg-overlayBlue text-white font-semibold font-helvetica"
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};

// Default props
Dropdown.defaultProps = {
  label: "",
  options: [],
  error: "",
  disabled: false,
  className: "",
  labelClassName: "",
  dropdownClassName: "",
};

// Prop types validation
Dropdown.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ),
  error: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  dropdownClassName: PropTypes.string,
  name: PropTypes.string,
};

export default Dropdown;
