import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import baseUrl from "baseUrl";
import { useState } from "react";
import apiService from "services/apiService";
import { Comment } from "../../Widgets/Comments";
import { IoMdClose, IoMdSend } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayRegister } from "features/userSlice";

const sendComment = async ({ postId, commentText }) => {
  const response = await apiService.post(
    `${baseUrl}/social/comment/${postId}`,
    { text: commentText }
  );
  return response.data;
};

const fetchComments = async (postId) => {
  const response = await apiService.get(
    `${baseUrl}/social/getComments/${postId}`
  );
  return response.data;
};

export const CommentsComponent = ({ postId, onClose }) => {
  const { userName } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isGuest = userName.startsWith("guest_");
  const [commentText, setCommentText] = useState("");
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: sendComment,
    onSuccess: (response) => {
      queryClient.setQueryData(["comments", postId], response);
    },
    onError: (error) => {
      console.error("Error while sending comment:", error);
    },
  });

  const handleSendComment = () => {
    if (isGuest) {
      dispatch(setDisplayRegister(true));
    }
    if (!commentText) return;
    mutation.mutate({ postId, commentText });
    setCommentText("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendComment();
    }
  };

  const { data } = useQuery({
    queryKey: ["comments", postId],
    queryFn: () => fetchComments(postId),
    onError: (error) => console.error("Error fetching comments:", error),
  });

  return (
    <div className=" z-10 w-[400px] top-0 left-0 h-full right-0">
      <div className="flex flex-col rounded-lg h-full bg-overlayBlue shadow-lg justify-center items-center p-2">
        <div className="flex justify-between items-center w-full px-4">
          <div className="subheading-small py-4 shadow-md text-white text-center ">
            Add a comment
          </div>
          <IoMdClose
            color="white"
            size={"1.25rem"}
            cursor={"pointer"}
            onClick={onClose}
          />
        </div>

        <div className="flex flex-col flex-1 overflow-y-auto w-full">
          {data && data.length > 0 ? (
            data.map((comment, key) => (
              <Comment
                key={key}
                setComments={() => {}}
                comment={comment}
                handleCommentCount={() => {}}
              />
            ))
          ) : (
            <div className="w-full h-full justify-center items-center flex">
              <p className="text-gray4 body-text font-bold ">No comments yet</p>
            </div>
          )}
        </div>
        <div className="flex justify-center items-center gap-2 w-full p-2">
          <input
            className="bg-overOverlayBlue w-[90%] p-2 rounded-md outline-none text-white"
            placeholder="Enter your comment..."
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button
            className="p-3 flex justify-center items-center bg-overOverlayBlue rounded-full"
            onClick={handleSendComment}
          >
            <IoMdSend color="white" />
          </button>
        </div>
      </div>
    </div>
  );
};
