import React, { useState } from "react";
import Modal from "react-modal";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";
import "./Onboarding.css";
Modal.setAppElement("#root");

export const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [generatedImages, setGeneratedImages] = useState([]);

  const handleNextStep = () => {
    if (currentStep === 3) {
      window.location.href = "/subscription";
      return;
    }
    setCurrentStep((step) => step + 1);
  };

  const getComponent = (step) => {
    switch (step) {
      case 1:
        return (
          <Step1
            handleNextStep={handleNextStep}
            setGeneratedImages={setGeneratedImages}
          />
        );
      case 2:
        return (
          <Step2
            handleNextStep={handleNextStep}
            generatedImages={generatedImages}
          />
        );
      case 3:
        return <Step4 handleNextStep={handleNextStep} />;
      // TODO: Add Step3 => Body type etc.
      case 4:
        return <Step3 handleNextStep={handleNextStep} />;
      default:
        return <Step4 handleNextStep={handleNextStep} />;
    }
  };
  return (
    <Modal
      isOpen={true}
      onRequestClose={() => {}}
      className="fixed inset-0 flex items-center justify-center p-4 h-screen w-full shadow-lg bg-white"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="w-full h-full md:w-2/3 md:h-[90%] md:rounded-3xl p-2 md:p-4 bg-[#0D1525] flex justify-center items-center overflow-y-auto">
        {getComponent(currentStep)}
      </div>
    </Modal>
  );
};
