import { createSlice } from "@reduxjs/toolkit";

const youaiInitialState = {
  selectedModel: {
    serviceName: "youai",
    description: "A versatile AI model for various tasks.",
    icon: "https://api.dicebear.com/9.x/icons/svg?seed=ai&backgroundColor=transparent",
    modelName: "YouAI",
    responseType: "general",
    credits: 0.03,
    loaderTime: 5000,
    visibility: ["staging", "production"],
    guestCredits: 0.2,
    modelNote: "This model takes text input.",
  },
};

export const youaiSlice = createSlice({
  name: "youai",
  initialState: youaiInitialState,
  reducers: {
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
  },
});

export const { setSelectedModel } = youaiSlice.actions;

export default youaiSlice.reducer;
