import React, { useRef, useState, useCallback } from "react";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import Gravatar from "react-gravatar";
import { useNavigate } from "react-router-dom";
import { FaRegComment } from "react-icons/fa";
import { BsSendFill } from "react-icons/bs";
import { RiThumbUpFill, RiThumbUpLine } from "react-icons/ri";
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5";
import { setDisplayRegister } from "features/userSlice";
import baseUrl from "baseUrl";
import apiService from "services/apiService";
import { useQueryClient } from "@tanstack/react-query";
import { updateItemInInfiniteQuery } from "../../Utility/UpdateFunctions";

const ReelComponent = ({ currentPost, handleCommentClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isPaused, setIsPaused] = useState(false);
  const videoRef = useRef(null);
  const { userName } = useSelector((state) => state.user);
  const isGuestUser = userName?.startsWith("guest_");

  // Extracted post data variables
  const postType = currentPost?.type;
  const profile = currentPost?.profile || {};
  const mediaUrls = currentPost?.mediaUrls || {};
  const conversation = currentPost?.conversation || {};
  const username = profile?.username || "Unknown";
  const imageUrl = profile?.imageUrl || null;

  // Extracted post attributes
  let videoUrl = "";

  if (postType === "normal") {
    if (mediaUrls) {
      videoUrl = mediaUrls.compressedImageUrl || mediaUrls.url || "";
    }
  } else if (conversation?.mediaUrls) {
    videoUrl =
      conversation.mediaUrls.compressedImageUrl ||
      conversation.mediaUrls.url ||
      "";
  }

  const caption =
    postType === "normal"
      ? currentPost?.caption || ""
      : conversation?.prompt || "";
  const userHash = CryptoJS.MD5(username).toString();

  const toggleLike = (postId, currentValue, currentLikeCount) => {
    const newLikeStatus = !currentValue;
    const newLikeCount = newLikeStatus
      ? currentLikeCount + 1
      : currentLikeCount - 1;

    updateItemInInfiniteQuery(queryClient, ["reels", userName], postId, {
      likedByUser: newLikeStatus,
      likeCount: newLikeCount,
    });
  };

  const toggleSave = (postId, currentValue) => {
    updateItemInInfiniteQuery(queryClient, ["reels", userName], postId, {
      saved: currentValue,
    });
  };

  const likePost = (postId) =>
    apiService.patch(`${baseUrl}/social/toggleLike/${postId}`);
  const savePost = (postId) =>
    apiService.post(`${baseUrl}/social/savePost/${postId}`);

  const handleVideoClick = useCallback(() => {
    if (videoRef.current) {
      isPaused ? videoRef.current.play() : videoRef.current.pause();
      setIsPaused((prev) => !prev);
    }
  }, [isPaused]);

  const handleLikeClick = useCallback(async () => {
    if (isGuestUser) {
      dispatch(setDisplayRegister(true));
      return;
    }

    const isLiked = currentPost?.likedByUser;
    const likeCount = currentPost?.likeCount;

    toggleLike(currentPost._id, isLiked, likeCount);

    try {
      await likePost(currentPost._id);
    } catch (error) {
      console.error("Error toggling like:", error);
      toggleLike(currentPost._id, !isLiked, likeCount);
    }
  }, [dispatch, isGuestUser, currentPost]);

  const handleSave = async () => {
    if (isGuestUser) {
      dispatch(setDisplayRegister(true));
      return;
    }

    const currentStatus = currentPost?.saved;
    toggleSave(currentPost?._id, !currentStatus);

    try {
      await savePost(currentPost._id);
    } catch (error) {
      console.error("Error saving post:", error);
      toggleSave(currentPost?._id, currentStatus);
    }
  };

  const ActionButton = ({ icon, label, onClick }) => (
    <div
      className="flex flex-col items-center justify-center cursor-pointer gap-1 text-white"
      onClick={onClick}
    >
      {icon}
      <span className="text-sm drop-shadow-md">{label}</span>
    </div>
  );

  return (
    <div className="relative flex flex-col items-center w-full h-full">
      {/* Video */}
      <video
        ref={videoRef}
        src={videoUrl}
        autoPlay
        playsInline
        loop
        className="w-full h-full object-cover rounded-lg cursor-pointer"
        onClick={handleVideoClick}
      />

      {/* Post Details */}
      <div className="absolute bottom-0 w-full text-white p-4 pr-10 rounded-b-lg">
        <div className="flex items-center gap-3 mb-2">
          <div
            className="w-10 h-10 rounded-full border border-white cursor-pointer"
            onClick={() => navigate(`/social_profile/${username}`)}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="Sender Avatar"
                className="w-full h-full object-contain rounded-full"
              />
            ) : (
              <Gravatar
                email={username}
                size={150}
                default="identicon"
                className="w-full h-full object-contain rounded-full"
                hash={userHash}
              />
            )}
          </div>
          <p className="font-semibold body-text">{username}</p>
        </div>
        <p className="text-sm text-gray-300">{caption}</p>
      </div>

      <div className="absolute bottom-0 right-0 h-2/5 py-4 w-14 flex flex-col justify-between items-center">
        <ActionButton
          icon={
            currentPost?.likedByUser ? (
              <RiThumbUpFill size="1.5rem" className="drop-shadow-md" />
            ) : (
              <RiThumbUpLine size="1.5rem" className="drop-shadow-md" />
            )
          }
          label={currentPost?.likeCount}
          onClick={handleLikeClick}
        />
        <ActionButton
          icon={<FaRegComment size="1.5rem" className="drop-shadow-md" />}
          label={currentPost?.commentCount ?? ""}
          onClick={handleCommentClick}
        />
        <ActionButton
          icon={
            currentPost?.saved ? (
              <IoBookmark size="1.5rem" className="drop-shadow-md" />
            ) : (
              <IoBookmarkOutline size="1.5rem" className="drop-shadow-md" />
            )
          }
          onClick={handleSave}
        />
        <ActionButton
          icon={<BsSendFill size="1.5rem" className="drop-shadow-md" />}
          label={currentPost?.shareCount ?? ""}
        />
      </div>
    </div>
  );
};

export default ReelComponent;
