import React, { useState, useEffect, useCallback } from "react";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { CSSTransition } from "react-transition-group";
import _ from "lodash";
import baseUrl from "baseUrl";
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import apiService from "services/apiService";
import ReelComponent from "./ReelComponent";
import "styles/socialFeatures/comments.css";
import "styles/SocialReels.css";
import { CommentsComponent } from "./CommentComponent";
import { useLocation } from "react-router-dom";

const fetchVideos = async ({ pageParam = 1 }) => {
  try {
    const response = await apiService.get(
      `${baseUrl}/social/reel-videos?page=${pageParam}`
    );
    if (response) {
      return {
        posts: response.posts,
        nextPage: pageParam + 1,
        hasMore: !response.isCompleted,
      };
    }
  } catch (e) {
    console.error(e.message);
    throw new Error("Error fetching posts");
  }
};

export const SocialReels = () => {
  const [currentReelIndex, setCurrentReelIndex] = useState(0);
  const [isCommentBoxOpen, setIsCommentBoxOpen] = useState(false);
  const { userName } = useSelector((state) => state.user);
  const location = useLocation();
  const fromSocial = location.state && location.state.fromSocial;
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueryData(["reels", userName]);
  const [shouldEnableQuery, setShouldEnableQuery] = useState(false);

  useEffect(() => {
    setShouldEnableQuery(fromSocial ? !cachedData : true);
  }, [fromSocial, cachedData]);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["reels", userName],
      queryFn: fetchVideos,
      initialPageParam: 1,
      getNextPageParam: (lastPage) =>
        lastPage.hasMore ? lastPage.nextPage : undefined,
      enabled: shouldEnableQuery,
    });

  const reels = _.flatMap(data?.pages || [], (page) => page.posts || []);
  console.log(reels);

  useEffect(() => {
    if (
      hasNextPage &&
      currentReelIndex >= reels.length - 5 &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  }, [
    currentReelIndex,
    reels.length,
    hasNextPage,
    fetchNextPage,
    setShouldEnableQuery,
    isFetchingNextPage,
  ]);

  const handleNext = useCallback(() => {
    setCurrentReelIndex((prev) => {
      const nextIndex = Math.min(prev + 1, reels.length - 1);

      if (nextIndex >= reels.length - 5) {
        setShouldEnableQuery(true);
      }

      return nextIndex;
    });
  }, [reels.length]);

  const handlePrevious = useCallback(() => {
    setCurrentReelIndex((prev) => Math.max(prev - 1, 0));
  }, []);

  const handleCommentClick = () => {
    setIsCommentBoxOpen((prev) => !prev);
  };

  return (
    <div className="w-full flex relative justify-center items-center h-[92dvh] md:h-full p-4 gap-2">
      <div className="flex w-[400px] rounded-lg h-full bg-overlayBlue shadow-lg justify-center items-center">
        <ReelComponent
          key={reels[currentReelIndex]?._id}
          currentPost={reels[currentReelIndex]}
          handleCommentClick={handleCommentClick}
        />
      </div>
      <div className="flex h-full relative">
        <CSSTransition
          in={isCommentBoxOpen}
          timeout={300}
          classNames="comment-box"
          unmountOnExit
        >
          <div>
            {isCommentBoxOpen && (
              <CommentsComponent
                postId={reels[currentReelIndex]?._id}
                onClose={handleCommentClick}
              />
            )}
          </div>
        </CSSTransition>
      </div>
      <div className="flex absolute right-0 md:relative flex-col justify-between items-center p-2 gap-4">
        <button
          className="p-3 bg-overOverlayBlue rounded-full text-lg cursor-pointer disabled:bg-gray-800 disabled:cursor-default"
          onClick={handlePrevious}
          disabled={currentReelIndex === 0}
        >
          <IoArrowUpOutline color={currentReelIndex === 0 ? "gray" : "white"} />
        </button>
        <button
          className="p-3 bg-overOverlayBlue rounded-full text-lg cursor-pointer"
          onClick={handleNext}
        >
          <IoArrowDownOutline color="white" />
        </button>
      </div>
    </div>
  );
};
