import React, { useState } from "react";
import Modal from "react-modal";
import GoogleAuthComponent from "./GoogleAuthComponent";
import { Checkbox } from "@mui/material";
import { LoginComponent } from "./LoginComponent";
import { RegisterComponent } from "./RegisterComponent";
import { useDispatch } from "react-redux";
import axios from "axios";
import baseUrl from "../../../baseUrl";
import { login, register } from "../../../services/auth";
import { handleLogin } from "../../../features/userSlice";
import { trackUserLogin } from "../../../services/mixpanelService";
import MainLogo from "assets/mainLogo-transformed.webp";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

const TermsAndCondition = ({ setTermsSelected }) => {
  return (
    <div className="flex text-white w-full items-center justify-center">
      <Checkbox
        onClick={(e) => setTermsSelected(e.target.checked)}
        color="success"
        sx={{ color: "white" }}
      />
      <p className="text-[12px]">I agree to </p>
      <a
        href="/termsandcondition"
        target="_blank"
        rel="noopener noreferrer"
        className="ml-2 text-sm text-blue-500 underline"
      >
        Terms & Conditions
      </a>
    </div>
  );
};

const RegisterModalV2 = ({ isOpen, onRequestClose, customMessage }) => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    email: "",
    gender: "male",
  });
  const [isRegisterMode, setIsRegisterMode] = useState(false);
  const [isTermsSelected, setTermsSelected] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function checkEmailExists(email) {
    try {
      const response = await axios.get(`${baseUrl}/auth/checkUserEmail`, {
        params: { email },
      });
      return response.data;
    } catch (error) {
      console.error("Error checking email:", error);
      throw error;
    }
  }

  // For Google Login
  const handleGoogleLoginSuccess = async (response) => {
    const tokens = response.credential.split(".");
    const user = JSON.parse(atob(tokens[1]));
    console.log("user: ", user);
    // Check email exists or not
    const checkEmailResponse = await checkEmailExists(user.email);

    if (!checkEmailResponse.exists) {
      setIsRegisterMode(true);
      setCredentials({ email: user.email, gender: "male" });
    } else {
      setCredentials({
        username: checkEmailResponse.username,
        password: checkEmailResponse.password,
      });
      const response = await login({
        username: checkEmailResponse.username,
        password: checkEmailResponse.password,
      });
      if (!response.error) {
        const { username, email, id, imageUrl, plan, credits } = response;
        dispatch(
          handleLogin({
            username: username,
            userEmail: email,
            userId: id,
            imageUrl: imageUrl,
            plan: plan,
            credits: credits,
          })
        );
        trackUserLogin(id);
      }
    }
  };

  // For Registering New User
  const handleRegisterSubmit = async () => {
    if (
      !credentials.username ||
      !credentials.email ||
      !credentials.password ||
      !credentials.confirmPassword ||
      !credentials.gender
    ) {
      setErrorMsg("All fields are required.");
      return;
    }

    if (!isTermsSelected) {
      setErrorMsg("Please accept our Terms and Conditions");
      return;
    }

    if (credentials.password !== credentials.confirmPassword) {
      setErrorMsg("Passwords do not match.");
      return;
    }

    try {
      const response = await register(credentials);
      if (response && response.error) {
        setErrorMsg(response.error);
      } else {
        const { username, email, credits, id, gender, plan } = response.user;
        setErrorMsg("");
        dispatch(
          handleLogin({
            username: username,
            userEmail: email,
            userId: id,
            imageUrl: null,
            plan: plan,
            credits: credits,
            gender: gender,
          })
        );
        trackUserLogin(id);
        navigate("/onboarding");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMsg("An error occurred.");
    }
  };

  // For Loggin existing user
  const handleLoginSubmit = async (e) => {
    if (!credentials.username || !credentials.password) {
      setErrorMsg("All fields are required.");
      return;
    }
    try {
      const response = await login({
        username: credentials.username,
        password: credentials.password,
      });
      if (response && response.error) {
        setErrorMsg(response.error);
      } else {
        const { username, email, id, imageUrl, plan, credits } = response;
        setErrorMsg("");
        dispatch(
          handleLogin({
            username: username,
            userEmail: email,
            userId: id,
            imageUrl: imageUrl,
            plan: plan,
            credits: credits,
          })
        );
        trackUserLogin(id);
      }
    } catch (error) {
      setErrorMsg("An error occurred.");
    }
  };

  // Utilities
  const handleChange = (e) => {
    setCredentials((prevCreds) => ({
      ...prevCreds,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc={customMessage}
      shouldCloseOnOverlayClick={customMessage}
      className="fixed inset-0 flex items-center justify-center p-4 h-screen w-full rounded-lg shadow-lg bg-white"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="w-4/5 md:w-2/3 h-[90%] rounded-3xl p-2 md:p-4 bg-[#0D1525] flex justify-center items-center">
        {/* FOR DISPLAY IMAGE */}
        <div className=" hidden md:block rounded-3xl relative w-[45%] h-full">
          <video
            src={
              "https://mygenerateddatabucket.s3.eu-north-1.amazonaws.com/Social/compressed_1733570368214.mp4"
            }
            autoPlay
            loop
            muted
            playsInline
            className="absolute top-0 left-0 w-full h-full object-cover rounded-3xl"
          />
          <div className="relative bg-[#40404069] rounded-3xl h-full p-4 z-10 flex items-start justify-start w-full">
            <div className="flex justify-start gap-2 items-center">
              <div className="w-10 lg:w-16">
                <img
                  src={MainLogo}
                  alt="logo"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="text-white flex flex-col">
                <span className="font-bold text-sm md:text-base">
                  Ideaverse AI
                </span>
                <span className="text-[10px] md:text-xs">
                  Making AI accessible to everyone
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* FOR COMPONENTS */}
        <div className="w-full md:w-[55%] h-full flex flex-col justify-center items-center gap-4">
          {isRegisterMode ? (
            <>
              <h1 className="loginregister">Register</h1>
              {credentials.email && (
                <div>
                  <p className="email-display">
                    {credentials.email.length > 30
                      ? `${credentials.email.substring(0, 27)}...`
                      : credentials.email}
                  </p>
                </div>
              )}
              <TermsAndCondition setTermsSelected={setTermsSelected} />
              <RegisterComponent
                credentials={credentials}
                handleChange={handleChange}
                handleRegisterSubmit={handleRegisterSubmit}
              />
            </>
          ) : (
            <>
              <GoogleAuthComponent
                handleGoogleLoginSuccess={handleGoogleLoginSuccess}
              />
              <p className="text-xs text-center text-red-400">
                {customMessage}
              </p>
              <LoginComponent
                handleChange={handleChange}
                credentials={credentials}
                handleLoginSubmit={handleLoginSubmit}
              />
            </>
          )}
          <div className="h-6">
            {errorMsg && <p className="error-message">{errorMsg}</p>}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RegisterModalV2;
