import React, { useEffect, Suspense, useState } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Header from "./components/Header";
import ProtectedRoute from "./routes/ProtectedRoute";
import PrivateRoute from "./routes/PrivateRoute";
import { getCredits, getNotifications, updateFcmToken } from "./services/auth";
import "./App.css";
import { HashLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { setCredits, updateNotification } from "./features/userSlice";
import { toast } from "react-toastify";
import useGuestAuth from "./hooks/useGuestAuthentication";
import {
  generatePermission,
  onMessage,
  isBrowserEnvironment,
} from "./firebase-config";
import {
  trackPageNavigation,
  trackSessionStart,
  trackSessionEnd,
  resetIdleTimer,
  onPageNavigation,
  getUserId,
  trackPageView,
} from "./services/mixpanelService";
import PaymentSuccess from "./Payments/PaymentSuccess";
import Sidebar from "Sidebar/Sidebar";
import { Box } from "@mui/material";
import useIsMobile from "hooks/useIsMobile";
import { GoSidebarExpand } from "react-icons/go";
import { Onboarding } from "Onboarding/Onboarding";
import TermsAndConditon from "landingPages/TermsAndCondition";
import _ from "lodash";
import useViewportHeightFix from "hooks/useViewportHeightFix";
import { SocialReels } from "socialFeature/socialPages/Reels/SocialReels";

const Capabilities = React.lazy(() => import("./landingPages/Capabilities"));
const Blog = React.lazy(() => import("./landingPages/Blog"));
const BlogPage = React.lazy(() => import("./landingPages/blogsPage"));
const Privacy = React.lazy(() => import("./landingPages/privacyPage"));
const DeleteAccount = React.lazy(
  () => import("./landingPages/deleteAccountPage")
);
const SaveAssets = React.lazy(() => import("./socialFeature/SaveAssets"));
const NotificationPage = React.lazy(
  () => import("./socialFeature/NotificationPage")
);
const ToolsHome = React.lazy(() => import("./tools/ToolsHome"));
const WritingAssistant = React.lazy(
  () => import("./tools/writingTool/WritingAssistant")
);
const PlanGenerator = React.lazy(
  () => import("./tools/businessPlan/PlanGenerator")
);
const ViewPost = React.lazy(
  () => import("./socialFeature/socialPages/ViewPost")
);
const FlashCard = React.lazy(() => import("./tools/FlashCard"));
const HexCodeGenerator = React.lazy(() => import("./tools/hexCodeGenerator"));
const FlashCardResult = React.lazy(() => import("./tools/FlashCardResults"));
const BusinessAiTool = React.lazy(() => import("./tools/businessAiTool"));
const BusinessAiDetailTool = React.lazy(
  () => import("./tools/businessAiToolDetail")
);
const InterviewAi = React.lazy(() => import("./tools/InterviewAi"));
const InterviewQuestion = React.lazy(() => import("./tools/interviewQuestion"));
const VoiceAssistant = React.lazy(() => import("./pages/VoiceAssistant"));
const RealTimeImageGeneration = React.lazy(
  () => import("./tools/RealTimeTool")
);
const TutorHome = React.lazy(() => import("./tools/tutor/TutorHome"));
const WorkoutForm = React.lazy(() => import("./tools/workout/WorkoutForm"));
const WorkoutList = React.lazy(() => import("./tools/workout/WorkoutList"));
const Study = React.lazy(() => import("./tools/tutor/Study"));
const CaptionForm = React.lazy(() => import("./tools/caption/CaptionForm"));
const Result = React.lazy(() => import("./tools/caption/Result"));
const LogoMaker = React.lazy(() => import("./tools/logoMaker/LogoMaker"));
const MessagePage = React.lazy(
  () => import("./socialFeature/socialPages/MessagePage")
);
const VoiceAssistant2 = React.lazy(() => import("./pages/VoiceAssi2.0"));
const PromptLeaderboard = React.lazy(
  () => import("./socialFeature/promptLeaderboard")
);
const AvatarGenerator = React.lazy(
  () => import("./tools/AI_Tools/AvatarGenerator")
);
const Avatar3D = React.lazy(() => import("./tools/AI_Tools/3dAvatar"));
const AvatarModel = React.lazy(() => import("./pages/AvatarWebView"));
const HomeMainPage = React.lazy(() => import("./components/HomeMainPage"));
const GlobalAiNews = React.lazy(() => import("./tools/GlobalAiNews"));
const GlobalAiNewsDetail = React.lazy(
  () => import("./tools/GlobalAiNewsDetail")
);
const MyAvatar = React.lazy(() => import("./pages/MyAvatar"));
const CreateAvatar = React.lazy(() => import("./pages/CreateAvatar"));
const MainAvatar = React.lazy(() => import("./pages/AvatarViewer"));
const AvatarYouAi = React.lazy(() => import("./components/AvatarYouAiChat"));
const AIChallenge = React.lazy(() => import("./pages/AIChallenge"));
const AIChallengeDetail = React.lazy(() => import("./pages/AIChallengeDetail"));
const InteractPost = React.lazy(
  () => import("./socialFeature/socialPages/InteractPost")
);
const SocialEditProfile = React.lazy(
  () => import("./socialFeature/socialPages/SocialEditProfile")
);
const Writer = React.lazy(() => import("./tools/novelWriting/Writer"));
const AvatarStore = React.lazy(() => import("./pages/AvatarStore"));
const Explore = React.lazy(() => import("./socialFeature/Explore"));
const SearchResults = React.lazy(
  () => import("./socialFeature/socialPages/SearchResults")
);
const ChangePassword = React.lazy(() => import("./pages/change-password"));
const Model = React.lazy(() => import("./pages/model"));
const Collection = React.lazy(() => import("./pages/collection"));
const Payments = React.lazy(() => import("./Payments/PaymentHome"));
const NotFound = React.lazy(() => import("./pages/404"));
const VoiceAssi = React.lazy(() => import("./pages/TTSpage"));
const AccountPage = React.lazy(() => import("./account/AccountMain"));
const HistoryStorage = React.lazy(
  () => import("./History_Components/HistoryMain")
);
const MainChatbot = React.lazy(() => import("./chatbot/MainChatbot"));
const SocialMain = React.lazy(() => import("./socialFeature/SocialMain"));
const SocialProfile = React.lazy(() => import("./socialFeature/SocialProfile"));
const TrendingPage = React.lazy(() => import("./socialFeature/TrendingPage"));
const LandingHomePage = React.lazy(() => import("./landingPages/Home"));
const CancellationPolicy = React.lazy(
  () => import("./landingPages/CancellationPolicy")
);
const CompanyAddress = React.lazy(
  () => import("./landingPages/RegisterAddress")
);

const ContactUs = React.lazy(() => import("./landingPages/ContactUs"));

const TrainingModels = React.lazy(
  () => import("./chatbot/components/TrainingModels")
);

const ChooseTemplatePage = React.lazy(
  () => import("./VideoEditingAI/ChooseTemplatePage")
);
const MusicLibrary = React.lazy(
  () => import("./VideoEditingAI/Library/MusicLibrary")
);
const VoiceLibrary = React.lazy(
  () => import("./VideoEditingAI/Library/VoiceLibrary")
);
const VideoEditorPage = React.lazy(
  () => import("./VideoEditingAI/VideoEditorPage")
);
const OutlineVideoPage = React.lazy(
  () => import("./VideoEditingAI/OutlineVideoPage")
);
const EditScriptPage = React.lazy(
  () => import("./VideoEditingAI/EditScriptPage")
);
const GenerateVideoPage = React.lazy(
  () => import("./VideoEditingAI/GenerateVideoPage")
);
const VoiceCloning = React.lazy(() => import("./VoiceCloning/MyVoices"));
const VoiceCloningPopup = React.lazy(
  () => import("./VoiceCloning/VoiceVerificationPopup")
);
const VoiceCloningPage = React.lazy(
  () => import("./VoiceCloning/VoiceCloningMainPage")
);

const FallBackLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "75vh",
        width: "100%",
      }}
    >
      <HashLoader color="#6cd97e" size={50} />
    </div>
  );
};

function App() {
  const { userName, userId, isAuth } = useSelector((state) => state.user);
  const [sidebarToggled, setSidebarToggled] = useState(true);

  const toggleSidebar = () => {
    setSidebarToggled((prev) => !prev);
  };

  const isMobileScreen = useIsMobile();
  const dispatch = useDispatch();

  const getUserCredits = async (source) => {
    const response = await getCredits(source);
    if (response?.data !== undefined) {
      dispatch(setCredits(response.data));
    }
  };
  const fetchNotifications = async () => {
    const response = await getNotifications();
    if (response && typeof response.count === "number") {
      dispatch(updateNotification(response.count));
    } else {
      console.error("Invalid count in response:", response);
    }
  };

  useEffect(() => {
    if (userName !== null && window.location.href !== "/") {
      getUserCredits();
      if (isBrowserEnvironment()) fetchNotifications();
    }
  }, [userName]);

  // Mixpanel services
  useEffect(() => {
    trackPageNavigation(userId);
    onPageNavigation(userId);
  }, []);

  useEffect(() => {
    // Track initial page load
    trackPageView(window.location.pathname);

    // Track on route changes
    const handleRouteChange = () => {
      trackPageView(window.location.pathname);
    };

    // Listen for route changes via back/forward buttons
    window.addEventListener("popstate", handleRouteChange);

    // Track clicks by observing URL changes
    const observer = new MutationObserver(() => {
      handleRouteChange();
    });

    // Observe changes to URL
    observer.observe(document.querySelector("body"), {
      subtree: true,
      childList: true,
    });

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (userId) {
      getUserId(userId);
      trackSessionStart(userId);

      window.addEventListener("beforeunload", () => trackSessionEnd(userId));
      resetIdleTimer();

      return () => {
        window.removeEventListener("beforeunload", () =>
          trackSessionEnd(userId)
        );
      };
    }
  }, [userId]);

  // GUEST USER CREATION
  useGuestAuth();
  useViewportHeightFix();

  useEffect(() => {
    const isBrowserEnv = isBrowserEnvironment();

    const requestPermissionAndUpdateToken = async () => {
      const storedToken = localStorage.getItem("fcmToken");
      const fcmToken = await generatePermission();
      if (fcmToken !== storedToken) {
        if (isBrowserEnv) await updateFcmToken({ fcmToken });
      }
    };
    if (isBrowserEnv) {
      requestPermissionAndUpdateToken();
      onMessage((payload) => {
        if (
          payload.notification.data &&
          payload.notification.data.sender === "admin"
        ) {
          console.log("pauly", payload);
          toast.info(payload.notification.title, {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            style: {
              borderRadius: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            },
          });
        } else {
          dispatch(updateNotification());
        }
      });
    }
  }, []);

  const hideSidebarAndHeader = [
    "/Home",
    "/capabilities",
    "/privacy",
    "/avatarModel",
    "/deleteAccount",
    "/onboarding",
    "/musicLibrary",
    "/voiceLibrary",
    "/termsandcondition",
    "/cancellationPolicy",
    "/companyAddress",
    "/PRIVACY",
    "/home",
    "/blog",
  ];

  const isHeaderVisible = !hideSidebarAndHeader.includes(
    window.location.pathname
  );

  const isSideBarVisible =
    isAuth && !hideSidebarAndHeader.includes(window.location.pathname);

  return (
    <div className="App">
      <BrowserRouter>
        <Box
          sx={{
            display: isMobileScreen ? "block" : "flex",
            position: "relative",
            height: "calc(var(--vh, 1vh) * 100)",
            backgroundColor: "#0d1525",
          }}
        >
          {isMobileScreen ? (
            isHeaderVisible ? (
              <Header />
            ) : null
          ) : isSideBarVisible ? (
            <>
              <GoSidebarExpand
                className="absolute top-[34px] left-[10px] text-white z-[100] cursor-pointer"
                size="1.5rem"
                onClick={toggleSidebar}
              />
              {sidebarToggled ? <Sidebar isOpen={sidebarToggled} /> : null}
            </>
          ) : null}
          <Box
            component="main"
            sx={
              isMobileScreen ||
              hideSidebarAndHeader.includes(window.location.pathname)
                ? null
                : {
                    flexGrow: 1,
                    px: 3,
                    overflow: "auto",
                  }
            }
          >
            <Suspense fallback={<FallBackLoader />}>
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path="/collection" element={<Collection />} />
                  <Route path="/collection/:collection" element={<Model />} />
                  <Route path="/history" element={<HistoryStorage />} />
                  <Route path="/aichat/avatar" element={<MainChatbot />} />
                  <Route path="/aichat/youai" element={<MainChatbot />} />
                  <Route path="/aichat/aichallenge" element={<MainChatbot />} />
                  <Route path="/aichat/voice/" element={<MainChatbot />} />
                  <Route
                    path="/aichat/voice/:prevSessionId"
                    element={<MainChatbot />}
                  />
                  <Route
                    path="/aichat/avatar/:prevSessionId"
                    element={<MainChatbot />}
                  />
                  <Route
                    path="/aichat/youai/:prevSessionId"
                    element={<MainChatbot />}
                  />
                  <Route
                    path="/aichat/aichallenge/:prevSessionId"
                    element={<MainChatbot />}
                  />
                  <Route path="/social_profile" element={<SocialProfile />} />
                  <Route path="/social_reels" element={<SocialReels />} />
                  <Route path="/saveAssets" element={<SaveAssets />} />
                  <Route path="/notification" element={<NotificationPage />} />
                  <Route path="/profile" element={<AccountPage />} />
                  <Route path="/VoiceAssi" element={<VoiceAssi />} />
                  <Route
                    path="/social/interact/:postId"
                    element={<InteractPost />}
                  />
                  <Route path="/social/explore" element={<Explore />} />
                  <Route path="/social/search" element={<SearchResults />} />
                  <Route
                    path="/promptLeaderboard"
                    element={<PromptLeaderboard />}
                  />
                  <Route path="/trainingModels" element={<TrainingModels />} />
                  <Route
                    path="/chooseTemplate"
                    element={<ChooseTemplatePage />}
                  />
                  <Route path="/videoEditor" element={<VideoEditorPage />} />
                  <Route path="/outlineVideo" element={<OutlineVideoPage />} />
                  <Route path="/editScript" element={<EditScriptPage />} />
                  <Route
                    path="/generateVideo"
                    element={<GenerateVideoPage />}
                  />
                  <Route
                    path="/VoiceCloning/myVoice"
                    element={<VoiceCloning />}
                  />
                  <Route
                    path="/VoiceCloning/voiceCloningMainPage"
                    element={<VoiceCloningPage />}
                  />
                  <Route
                    path="/VoiceCloning/voiceCloningPopup"
                    element={<VoiceCloningPopup />}
                  />
                  <Route path="/edit-profile" element={<SocialEditProfile />} />
                  <Route path="/musicLibrary" element={<MusicLibrary />} />
                  <Route path="/voiceLibrary" element={<VoiceLibrary />} />

                  <Route path="/" element={<HomeMainPage />} />
                  <Route path="/myAvatar" element={<MyAvatar />} />
                  <Route path="/createAvatar" element={<CreateAvatar />} />
                  <Route
                    path="/createAvatar/:avatarId"
                    element={<CreateAvatar />}
                  />
                  <Route path="/avatarStore" element={<AvatarStore />} />
                  <Route
                    path="/aiChallengeDetail"
                    element={<AIChallengeDetail />}
                  />
                  <Route path="/aiChallenge" element={<AIChallenge />} />
                  <Route path="/avatarYouAi/:id" element={<AvatarYouAi />} />
                  <Route
                    path="/avatarYouAi/:id/:sessionId"
                    element={<AvatarYouAi />}
                  />
                  <Route
                    path="/avatarGenerator"
                    element={<AvatarGenerator />}
                  />
                  <Route path="/avatar3d" element={<Avatar3D />} />
                  <Route path="/avatarViewer" element={<MainAvatar />} />
                  <Route path="/toptrends/:tag" element={<TrendingPage />} />
                  <Route
                    path="/social_profile/:username"
                    element={<SocialProfile />}
                  />
                  <Route path="/messaging" element={<MessagePage />} />
                  {/* ######################### AI TOOLS #################### */}
                  <Route path="/tools" element={<ToolsHome />} />
                  <Route
                    path="/tools/writing-assistant"
                    element={<WritingAssistant />}
                  />
                  <Route path="/tools/novel-writer" element={<Writer />} />
                  <Route
                    path="/tools/business-ai-detail"
                    element={<BusinessAiDetailTool />}
                  />
                  <Route
                    path="/tools/business-plan"
                    element={<PlanGenerator />}
                  />
                  <Route path="/tools/tutor" element={<TutorHome />} />
                  <Route path="/tools/tutor-study" element={<Study />} />
                  <Route path="/tools/workout" element={<WorkoutForm />} />
                  <Route path="/tools/workout-list" element={<WorkoutList />} />
                  <Route path="/tools/flash_card" element={<FlashCard />} />
                  <Route
                    path="/tools/real-time-image"
                    element={<RealTimeImageGeneration />}
                  />
                  <Route
                    path="/tools/flash_card_results"
                    element={<FlashCardResult />}
                  />
                  <Route
                    path="/tools/hex_code_generator"
                    element={<HexCodeGenerator />}
                  />
                  <Route
                    path="/tools/business_ai_tool"
                    element={<BusinessAiTool />}
                  />
                  <Route path="/tools/caption" element={<CaptionForm />} />
                  <Route path="/tools/caption-result" element={<Result />} />
                  <Route path="/tools/logo" element={<LogoMaker />} />
                  <Route path="/voice_assistant" element={<VoiceAssistant />} />
                  <Route path="/voice2" element={<VoiceAssistant2 />} />
                  <Route path="/tools/interviewAi" element={<InterviewAi />} />
                  <Route
                    path="/tools/interviewQuestion"
                    element={<InterviewQuestion />}
                  />
                  <Route path="/onboarding" element={<Onboarding />} />
                </Route>

                {/* UNPROTECTED ROUTES */}
                <Route path="/subscription" element={<Payments />} />
                <Route path="/payment" element={<Payments />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/success" element={<PaymentSuccess />} />
                </Route>
                <Route path="/social" element={<SocialMain />} />
                <Route path="/collection" element={<Collection />} />
                <Route path="/collection/:collection" element={<Model />} />
                <Route
                  path="/globalAiNewsDetail/:id"
                  element={<GlobalAiNewsDetail />}
                />
                <Route path="/globalAiNews" element={<GlobalAiNews />} />
                <Route
                  path="/change-password/:reset_token?"
                  element={<ChangePassword />}
                />
                <Route path="/social/:postId" element={<ViewPost />} />
                <Route path="/*" element={<NotFound />} />
                <Route path="/Home" element={<LandingHomePage />} />
                <Route path="/avatarModel" element={<AvatarModel />} />
                <Route path="/capabilities" element={<Capabilities />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blogPage/:id" element={<BlogPage />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/Home" element={<LandingHomePage />} />

                <Route
                  path="/cancellationPolicy"
                  element={<CancellationPolicy />}
                />
                <Route path="/companyAddress" element={<CompanyAddress />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/deleteAccount" element={<DeleteAccount />} />
                <Route
                  path="/termsandcondition"
                  element={<TermsAndConditon />}
                />

                <Route path="/404" element={<NotFound />} />

                {/* 
              Don't know we need to keep this feature or not
              <Route path="/DashBoard" element={<DashBoard />} />
              <Route path="/myspace" element={<MySpace />} />
              <Route path="/dailyrecap" element={<Recap />} />
              <Route path="/goals" element={<DailyGoals />} />
              <Route path="/dailyrecord" element={<DailyRecord />} />
              
            */}
              </Routes>
            </Suspense>
          </Box>
        </Box>
      </BrowserRouter>
    </div>
  );
}

export default App;
