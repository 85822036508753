import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

export default function CustomSlider({ options }) {
  // Prepare the marks with color indicators
  const marks = options.map((option, index) => ({
    value: index * (100 / (options.length - 1)), // Position the mark evenly
    label: (
      <Box
        sx={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          backgroundColor: option.value,
          border: "0px",
          boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)",
        }}
      ></Box>
    ),
  }));

  const [value, setValue] = React.useState(0);

  // Handle slider value change
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  // Determine the thumb color based on the value
  const thumbColor =
    options[Math.round(value / (100 / (options.length - 1)))].value;

  return (
    <Box sx={{ width: "100%", height: "11vh", position: "relative" }}>
      <Slider
        aria-label="Skin Tone"
        value={value}
        onChange={handleSliderChange}
        step={null}
        marks={marks}
        valueLabelDisplay="off"
        size="large"
        min={0}
        max={100}
        sx={{
          marginBottom: "70px",
          "& .MuiSlider-markLabel": {
            bottom: "36px", // Moves marks above the slider
          },
          "& .MuiSlider-mark": {
            display: "none", // Hides default marks
          },
          "& .MuiSlider-thumb": {
            backgroundColor: thumbColor, // Dynamically set thumb color
          },
          "& .MuiSlider-track": {
            backgroundColor: "white", // Set the track color to white
          },
          "& .MuiSlider-rail": {
            backgroundColor: "white", // Set the rail color to white
          },
        }}
      />
    </Box>
  );
}
