import React, { useState } from "react";

export const Step2 = ({ handleNextStep, generatedImages }) => {
  return (
    <div className="flex flex-col justify-between items-center w-full h-full p-4">
      {/* Grid */}
      <div className="w-full flex-grow grid grid-cols-3 gap-4 overflow-y-auto scrollbar-thin scrollbar-thumb-[#4BC25E] scrollbar-track-[#1B2333] px-2">
        {generatedImages?.map((image, id) => (
          <div key={id} className="w-full h-auto">
            <img
              src={image}
              alt={`image-${id}`}
              className="w-full h-full object-cover rounded-lg"
            />
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center">
        <button
          className="w-1/2 mt-4 text-[#1B2333] font-semibold bg-[#4BC25E] rounded-lg p-3 text-sm active:scale-95 transition-transform duration-150"
          onClick={handleNextStep}
        >
          Next
        </button>
      </div>
    </div>
  );
};
