import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { customizedMenuItems } from "./SidebarMenuItems";
import MainLogo from "assets/mainLogo-transformed.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import { handleLogout, setDisplayRegister } from "features/userSlice";
import { deleteCookie } from "utils/methods";
import { trackExitPoint } from "services/mixpanelService";
import DollarImage from "assets/dollar-symbol.png";

export default function Sidebar({ isOpen }) {
  const [openSubMenus, setOpenSubMenus] = useState({});
  const { isAuth, credits, userId, userName } = useSelector(
    (state) => state.user
  );
  const [menuItems, setMenuItems] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuClick = () => {};

  const handleToggle = (label) => {
    setOpenSubMenus((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const handleLogoutButton = (e) => {
    deleteCookie("token");
    dispatch(handleLogout());
    dispatch(setDisplayRegister(true));
    trackExitPoint("Logout", userId);
  };

  const isLinkActive = (itemLink) => {
    if (!itemLink) return false;

    // Extract the base path from the current location
    const currentPath = location.pathname.split("?")[0];

    // Extract the base path from the menu item link
    const itemPath = itemLink.split("?")[0];

    // For AI Chat specifically
    if (itemPath === "/aichat/youai") {
      return currentPath.startsWith("/aichat/youai");
    }

    return currentPath === itemPath;
  };

  const renderMenuItems = (items, isSubItem = false) => {
    return items.map((item) => {
      const isActive = isLinkActive(item.link);

      return (
        <React.Fragment key={item.label}>
          <ListItem
            sx={{
              color: isSubItem ? "gray" : "white",
              fontSize: isSubItem ? "10px" : "12px",
            }}
            disablePadding
          >
            <ListItemButton
              onClick={() => {
                if (item.label.startsWith("Sign")) {
                  handleLogoutButton();
                } else if (item.link) {
                  navigate(item.link);
                } else if (item.subItems) {
                  handleToggle(item.label);
                }
              }}
              sx={{
                width: "100%",
                gap: "4px",
                ...(!isSubItem && {
                  backgroundColor: isActive
                    ? "rgba(255, 255, 255, 0.1)"
                    : "inherit",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                }),
                ...(isSubItem && {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px 2rem",
                }),
              }}
            >
              {!isSubItem && (
                <ListItemIcon
                  sx={{
                    minWidth: "40px",
                    color: isActive ? "#6cd97e" : "inherit",
                  }}
                >
                  {React.isValidElement(item.icon)
                    ? React.cloneElement(item.icon, {
                        style: { color: isActive ? "#6cd97e" : "inherit" },
                      })
                    : null}
                </ListItemIcon>
              )}
              <ListItemText
                primaryTypographyProps={{
                  sx: { fontSize: isSubItem ? "13px" : "14px" },
                }}
                primary={item.label}
                sx={{
                  color: isActive
                    ? "#6cd97e"
                    : isSubItem
                      ? "#878E95"
                      : "inherit",
                  padding: isSubItem ? "8px 2rem" : "8px",
                  borderLeft: isSubItem ? "2px solid #e0e0e0" : "none",
                  margin: "0px",
                  ...(isSubItem && {
                    backgroundColor: isActive
                      ? "rgba(255, 255, 255, 0.1)"
                      : "inherit",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                    },
                  }),
                }}
              />
              {item.subItems ? (
                openSubMenus[item.label] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </ListItemButton>
          </ListItem>
          {item.subItems && (
            <Collapse
              in={openSubMenus[item.label]}
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                {renderMenuItems(item.subItems, true)}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      );
    });
  };

  useEffect(() => {
    const items = customizedMenuItems(userName, isAuth);
    setMenuItems(items);
  }, [isAuth, userName]);

  return (
    <Box
      sx={{
        width: "250px",
        paddingTop: "20px",
        flexShrink: 0,
        overflow: "auto",
        position: "sticky",
        top: 0,
        height: "100vh",
        borderRight: "1px solid #000411",
      }}
      className={isOpen ? "open" : ""}
    >
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "5px 45px",
        }}
      >
        <div className="w-12 h-12 overflow-hidden">
          <img src={MainLogo} alt="logo" />
        </div>
        <p className="text-base text-white font-bold">Ideaverse.ai</p>
      </Box>
      <List>{renderMenuItems(menuItems)}</List>
    </Box>
  );
}
