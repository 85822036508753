import axios from "axios";
import { getCookie } from "../utils/methods";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_APP_API}/api`,
  timeout: 300000,
});

const apiRequest = async (method, url, data = {}, headers = {}) => {
  try {
    const response = await api({
      method,
      url,
      data,
      headers: {
        "Content-Type": "application/json",
        "auth-token": getCookie("token"),
        ...headers,
      },
    });
    return response.data;
  } catch (error) {
    console.error("API Error:", error);

    if (error?.response?.data?.message) {
      return { error: error.response.data.message };
    }

    if (error?.response?.data?.error) {
      return { error: error.response.data.error };
    }

    return { error: error.message || "Something went wrong" };
  }
};

const apiService = {
  get: (url, params = {}, headers = {}) =>
    apiRequest("get", url, null, { params, ...headers }),
  post: (url, data = {}, headers = {}) =>
    apiRequest("post", url, data, headers),
  put: (url, data = {}, headers = {}) => apiRequest("put", url, data, headers),
  patch: (url, data = {}, headers = {}) =>
    apiRequest("patch", url, data, headers),
  delete: (url, data = {}, headers = {}) =>
    apiRequest("delete", url, data, headers),
};

export default apiService;
