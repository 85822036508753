import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userName: null,
  userId: null,
  userEmail: null,
  isAuth: false,
  ipAddress: null,
  guestAccount: true,
  credits: 0,
  displayRegister: false,
  imageUrl: null,
  userInterests: [],
  notificationCount: 0,
  plan: null,
  name: null,
  gender: null,
  pronouns: null,
  bio: null,
  allowedCollaborators: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setIpAddress: (state, action) => {
      state.ipAddress = action.payload;
    },
    setCredits: (state, action) => {
      state.credits = action.payload;
    },
    setGuestAccount: (state, action) => {
      state.guestAccount = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setPronouns: (state, action) => {
      state.pronouns = action.payload;
    },
    handleLogout: (state, action) => {
      state.userName = null;
      state.userId = null;
      state.userEmail = null;
      state.isAuth = false;
      state.credits = 0;
      state.ipAddress = null;
      state.userInterests = [];
      state.imageUrl = null;
      state.name = null;
      state.gender = null;
      state.bio = null;
      state.pronouns = null;
    },
    setUpdatedProfile: (state, action) => {
      state.name = action.payload.name;
      state.gender = action.payload.gender;
      state.pronouns = action.payload.pronouns;
      state.bio = action.payload.bio;
      state.imageUrl = action.payload.imageUrl;
    },
    handleLogin: (state, action) => {
      state.userName = action.payload.username;
      state.userEmail = action.payload.userEmail;
      state.userId = action.payload.userId;
      state.isAuth = true;
      state.displayRegister = false;
      state.imageUrl = action.payload.imageUrl;
      state.plan = action.payload.plan;
      state.gender = action.payload.gender;
    },
    setDisplayRegister: (state, action) => {
      state.displayRegister = action.payload;
    },
    setUserInterests: (state, action) => {
      state.userInterests = action.payload;
    },
    setUserPlan: (state, action) => {
      state.plan = action.payload;
    },
    setImageUrl: (state, action) => {
      state.imageUrl = action.payload;
    },
    updateNotification: (state, action) => {
      if (action.payload !== undefined) {
        state.notificationCount = action.payload;
      } else {
        state.notificationCount += 1;
      }
    },
    updateCollaborator: (state, action) => {
      state.allowedCollaborators = [
        ...state.allowedCollaborators,
        action.payload,
      ];
    },
    updateCollaborationList: (state, action) => {
      state.allowedCollaborators = action.payload;
    },
    removeCollaborator: (state, action) => {
      state.allowedCollaborators = state.allowedCollaborators.filter(
        (collaborator) => collaborator.username !== action.payload.username
      );
    },
  },
});
export const {
  setUserEmail,
  setUserName,
  setUserId,
  setAuth,
  setIpAddress,
  setCredits,
  handleLogout,
  setGuestAccount,
  setDisplayRegister,
  setUserInterests,
  setImageUrl,
  updateNotification,
  setUserPlan,
  setName,
  setGender,
  setPronouns,
  setUpdatedProfile,
  handleLogin,
} = userSlice.actions;
export default userSlice.reducer;
