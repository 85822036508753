import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "./store/store";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IoMdClose } from "react-icons/io";
import "react-tooltip/dist/react-tooltip.css";

const container = document.getElementById("root");
if (!container) {
  throw new Error("Root container is missing in the DOM");
}
const root = createRoot(container);

const query = new QueryClient();

if (process.env.REACT_APP_PROD_ENV === "true") {
  ["log", "error", "warn"].forEach((method) => {
    console[method] = () => {};
  });
}

const renderApp = () => (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <HelmetProvider>
          <QueryClientProvider client={query}>
            <App />
            <ToastContainer
              position="top-right"
              autoClose={1500}
              newestOnTop
              closeOnClick
              pauseOnFocusLoss
              draggable
              stacked={true}
              draggablePercent={60}
              toastClassName="toast-custom"
              closeButton={<IoMdClose color="#ccc" />}
            />
          </QueryClientProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

root.render(renderApp());
reportWebVitals();
