import React from "react";
import PropTypes from "prop-types";

export const TextArea = ({
  label,
  value,
  onChange,
  placeholder,
  rows = 4,
  error,
  disabled = false,
  className = "",
  labelClassName = "",
  textAreaClassName = "",
}) => {
  return (
    <div className={`flex flex-col w-full ${className}`}>
      {label && (
        <label
          className={`mb-2 text-sm font-medium text-white ${labelClassName}`}
          htmlFor={label}
        >
          {label}
        </label>
      )}
      <textarea
        id={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        disabled={disabled}
        className={`text-white font-semibold font-helvetica border-0 placeholder:text-gray4 text-sm  ${
          error ? "border border-red-500" : ""
        } rounded-md p-4  focus:outline-none resize-none ${
          disabled ? "bg-gray-100 cursor-not-allowed" : "bg-overlayBlue"
        } ${textAreaClassName}`}
      />
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};

// Default props
TextArea.defaultProps = {
  label: "",
  placeholder: "",
  rows: 4,
  error: "",
  disabled: false,
  className: "",
  labelClassName: "",
  textAreaClassName: "",
};

// Prop types validation
TextArea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  textAreaClassName: PropTypes.string,
};
