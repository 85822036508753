import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import useIsMobile from "hooks/useIsMobile";

const GoogleAuthComponent = ({ handleGoogleLoginSuccess }) => {
  const isMobileScreen = useIsMobile();

  return (
    <div>
      {!isMobileScreen ? (
        <h1 className="loginregister">Login or Register</h1>
      ) : null}
      <div className="flex justify-center items-center w-full">
        <GoogleOAuthProvider>
          <GoogleLogin
            client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            ux_mode="popup"
            scope={"profile email"}
            onSuccess={handleGoogleLoginSuccess}
            onError={(error) => {
              console.error("Google Login Failure:", error);
            }}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className={`google-button ${
                  isMobileScreen ? "mobile-google-button" : ""
                }`}
              >
                {isMobileScreen ? (
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                    alt="Google Logo"
                    className="google-logo"
                  />
                ) : (
                  "Sign in with Google"
                )}
              </button>
            )}
          />
        </GoogleOAuthProvider>
      </div>
    </div>
  );
};

export default GoogleAuthComponent;
