import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

function CircularProgressLoader({ duration = 60000, onComplete }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const steps = Math.max(Math.floor(duration / 500), 1);
    const intervalTime = duration / steps;
    const incrementBefore90 = 2;
    const incrementAfter90 = 1;

    let currentProgress = 0;
    let currentStep = 0;

    const interval = setInterval(() => {
      if (currentStep < steps) {
        let increment = incrementBefore90;
        if (currentProgress >= 90) {
          increment = incrementAfter90;
        }
        const nextProgress = Math.min(currentProgress + increment, 100);
        currentProgress = nextProgress;

        setProgress(nextProgress);
        if (currentProgress >= 100) {
          clearInterval(interval);
          if (onComplete) onComplete();
        }

        currentStep++;
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [duration, onComplete]);

  return (
    <div className="flex gap-2 items-center">
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
        }}
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <CircularProgress
          variant="determinate"
          value={progress}
          sx={{
            color: "#1B2333",
            thickness: 3,
            width: "20px !important",
            height: "20px !important",
          }}
        />
      </Box>
      {Math.round(progress) < 30 ? "Uploading..." : "Generating..."}
    </div>
  );
}

export default CircularProgressLoader;
