import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import Input from "../../common/Input";
import { DotLoader } from "react-spinners";
import { css } from "@emotion/react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const LoginComponent = ({
  handleChange,
  handleLoginSubmit,
  credentials,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((passwordVisible) => !passwordVisible);
  };

  const handleLoginClick = async () => {
    try {
      setLoading(true);
      await handleLoginSubmit();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex flex-col gap-1 md:gap-2 w-[90%] md:w-4/5">
      <Input
        name="username"
        type="text"
        placeholder="Username"
        value={credentials.username}
        onChange={handleChange}
        required
        className="inputBar1"
      />
      <div className="floeuy">
        <Input
          name="password"
          type={passwordVisible ? "text" : "password"}
          placeholder="Password"
          value={credentials.password}
          onChange={handleChange}
          required
          className="inputBar1"
        />
        <div onClick={togglePasswordVisibility}>
          {passwordVisible ? (
            <EyeOffIcon className="eyeIcpn" />
          ) : (
            <EyeIcon className="eyeIcpn" />
          )}
        </div>
      </div>
      <div className="w-full flex justify-center items-center mt-2">
        <button
          onClick={handleLoginClick}
          className="w-full rounded-xl md:rounded-md bg-[#4BC25E] p-3 text-[#1B2333] flex justify-center"
        >
          {loading ? (
            <DotLoader
              color={"#fff"}
              loading={loading}
              css={override}
              size={20}
            />
          ) : (
            <span className="font-semibold text-sm md:text-base">Login</span>
          )}
        </button>
      </div>
    </div>
  );
};
